import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import './checklists.css';
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { onError } from "../../libs/errorLib";
import SafetyService from "../../services/safety.service";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getBase64 } from "../common/imageHelper";
import fileIcon from "../../images/openfolderwhite.svg";
import PDFFiles from "../common/pdfRenderer/files";

import { Spinner } from "react-bootstrap";
import {
  PDFDownloadLink,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";

export default function ViewSafetyChecklist({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, user, userPermissions } = useAppState();
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);

  const fieldValues = {
    verifySignature: null,
    resubmitComment: null,
    files: "",
  };
  const [data, setData] = useState({
    overallScore: 0,
    closedIssues: null,
    openIssues: null,
    carryOverIssues: null,
  });
  

  const [safetyChecklist, setSafetyChecklist] = useState({});
  const [safetyChecklistAnswers, setSafetyChecklistAnswers] = useState({});
  const [existingAnswers, setExistingAnswers] = useState({});
  const [triggerSaveProgress, setTriggerSaveProgress] = useState(false);
  
  const [template, setTemplate] = useState([]);
  const [sections, setSections] = useState([]);
  const [templateItems, setTemplateItems] = useState([]);
  const [actionFiles, setActionFiles] = useState([]);
  const [closureFiles, setClosureFiles] = useState([]);

  const [scoreData, setScoreData] = useState({
    overallScore: 0,
  });

  const [fieldData, setFieldData] = useState({});
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({
    ...fieldValues,
    contractorId: null,
    date: null,
  });

  const [siteUsers, setSiteUsers] = useState([])

  const [sectionScores, setSectionScores] = useState({})
  const [totalPercentage, setTotalPercentage] = useState(0)

  // Popup Data
  const [showActionPopup, setShowActionPopup] = useState("");
  const [actionRequired, setActionRequired] = useState([]);
  
  const [actionPopup, setActionPopup] = useState("");
  const [actionData, setActionData] = useState({});
  const [popupData, setPopupData] = useState({});
  
  const [detailsPopup, setDetailsPopup] = useState("");
  const [detailsData, setDetailsData] = useState({});


  const fetchSafetyChecklistDetails = async () => {
    setLoading(true);
    try {
      if (id) {
        const response = await SafetyService.getSafetyChecklistDetails(id);
        if (response) {
          setSafetyChecklist(response);
          getSiteUsers(response.siteId);
          getChecklistTemplate();
          setFiles(''); // Reset Files.
        }
      }
    } catch (e) {
      onError(e || "Unable to process request");
      if ( e?.response?.data?.message && e?.response?.data?.message.search('have access') ) {
        history.push('/');
      }
      console.log("errr", e);
    } finally {
      setLoading(false);
    }
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        if (siteUsersResponseData.length) {
          setSiteUsers(siteUsersResponseData);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  async function getChecklistTemplate() {
    setLoading(true);
    try {
      if (safetyChecklist && safetyChecklist.templateId) {
        const response = await SafetyService.getSafetyTemplateDetails(
          safetyChecklist.templateId
        );
        if (response) {
          setTemplate(response);
          getTemplateItems();
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function getTemplateSections() {
    setLoading(true);
    try {
      if (template && template.sections) {
        const response = await SafetyService.getTemplateSections(template.id);
        if (response) {
          setSections(response);
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function getTemplateItems() {
    setLoading(true);
    try {
      if (template && template.id) {
        const response = await SafetyService.getTemplateItems(template.id);
        if (response) {
          const items = [];
          if (template.sections && sections.length) {
            sections.forEach((section) => {
              items[section.id] = response.filter(
                (item) => item.sti_sectionId == section.id
              );
              items[section.id] = items[section.id].sort(
                (a, b) => a.sti_sortingOrder - b.sti_sortingOrder
              );
            });
            setTemplateItems(items);
          } else {
            setTemplateItems(response);
          }
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchSafetyChecklistAnswers() {
    setLoading(true);
    try {
      const response = await SafetyService.getChecklistAnswers(id);
      if (response) {
        const rows = response.rows;
        const items = [];
        var totals = { total: 0, no: 0, yes: 0, na: 0 };
        let existingChosenAnswers = {};
        
        rows.forEach((item) => {
          items[item.sectionId + "_" + item.questionId] = item;

          sectionScores[item.sectionId] = sectionScores[item.sectionId] || {total: 0, yes: 0, no: 0, na: 0};
          
          sectionScores[item.sectionId].total++;
          totals.total++;

          existingChosenAnswers['q_' + item.sectionId + "_" + item.questionId] = item;

          if ( item.answer == 1 ) {
            sectionScores[item.sectionId].yes++;
            totals.yes++;
          } else if ( item.answer == 0 ) {
            sectionScores[item.sectionId].no++;
            totals.no++;
          } else {
            sectionScores[item.sectionId].na++;
            totals.na++;
          }
        });
        
        setExistingAnswers(existingChosenAnswers);

        Object.keys( sectionScores ).forEach( key => {
          sectionScores[key].percentage = (((sectionScores[key].yes + sectionScores[key].na) / sectionScores[key].total) * 100).toFixed(2).replace('.00', '')
        })

        setSafetyChecklistAnswers( items );
        setFiles(response.files);

        if ( totals.total > 0 ) {
          setTotalPercentage( (((totals.yes + totals.na) / totals.total) * 100).toFixed(2).replace('.00', '' ) );
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e, item) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {
      
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }

      if ( name.startsWith("q_")  ) {
        if ( value != 2 ) {
          setActionFiles([]);
          
          // POPUP DISABLED FOR NOW, MAY NEED IN FUTRE.
          if ( false && value == 1 ) {
            // Don't show the popup for true values.
            setActionRequired({
              ...actionRequired,
              [name]: {
                ...actionRequired[name],
                comment: '',
                status: '',
                chosenValue: value,
                item
              }
            })
          } else {
            setShowActionPopup(name);
            setActionRequired({
              ...actionRequired,
              [name]: {
                ...actionRequired[name],
                chosenValue: value,
                item
              }
            })
          }
          
        } else {
          // Clear any comments/etc below question.
          let newSafetychecklistAnswers = { ...safetyChecklistAnswers };
          
          newSafetychecklistAnswers[ (item.sti_sectionId || '0' ) + '_' + item.si_id ] = {
            assignTo: '',
            comment: '',
            status: '',
            actionRequired: '',
            date: '',
          };

          setSafetyChecklistAnswers(newSafetychecklistAnswers);

          setActionRequired({
            ...actionRequired,
            [name]: {
              ...actionRequired[name],
              chosenValue: value,
              item,
              status: '',
              comment: '',
              assignTo: null,
              actionRequired: null,
            }
          });
          
          setTriggerSaveProgress(true);
        }
        
      } else {
      }
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all( [ handleSaveVerifySign() ] )
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      // toast.error("Failed to save signature")
      handleSubmitAfterSave('')
    }
  }

  const saveProgress = async () => {
    handleSubmitAfterSave( false, true );
  }

  const handleSubmitAfterSave = (verifySignature, saveProgress = false) => {
    fieldData.actionsData = actionRequired;

    console.log(actionRequired);
    
    if ( false && ! verifySignature ) {
      toast.error("Signature is required");
      return;
    }

    const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("safetyChecklists") : v.permits.includes("safetyChecklistsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      form.append("checklistId", id );
      
      form.append("additionalComments", fieldData.additionalComments ? fieldData.additionalComments : "");
      
      form.append("verifySignature", verifySignature);
      
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      // add actions files too...
      Object.entries(actionRequired)?.map(([key, value]) => {
          form.append(`actionRequired[${key}][actionRequired]`, JSON.stringify( value ));
          form.append(`actionRequired[${key}]['files]`, value.files);

          if ( value.files ) {
            for (let index = 0; index <  value.files.length; index++) {
              const file =  value.files[index];
              form.append(`actionRequired[${key}][files][${index}]`, file);
            }
          }
      });

      if ( ! saveProgress ) {
        setLoading(true);
      } else {
        const apiCall = axiosInstance
        .put(`${process.env.REACT_APP_API_BASEURL}/update-details/progress-safety-checklist/${id}`, form, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        apiCall.then((res) => {
          setTriggerSaveProgress(false);
        } );
        return; // Let's not do anything yet.
      }

      const apiCall = axiosInstance
        .put(`${process.env.REACT_APP_API_BASEURL}/update-details/safety-checklist/${id}`, form, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
      });

      apiCall.then((res) => {
        if ( ! saveProgress ) {
          toast.success(res.data.message);
          setLoading(false);
          history.push('/safety-checklists')
        }
      })
        .catch((err) => {
          if ( ! saveProgress ) {
            toast.error(
              err?.response?.data?.message || "Unable to process this request"
            );
          }
          setLoading(false);
        });
  }

  const handleActionTaken = (e) => {
    if ( ! popupData.actionTaken ) {
      toast.error("Describe what action you have taken");
      return;
    }

    const form = new FormData();

    form.append("checklistId", id );
    form.append("sectionId", popupData.sectionId );
    form.append("itemId", popupData.itemId );
    form.append("actionTaken", popupData.actionTaken );

    if ( popupData.closureFiles ) {
      Object.entries(popupData?.closureFiles)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
    }

    const apiCall = axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/safety-checklist/action-taken`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
    });

    apiCall.then((res) => {
      toast.success(res.data.message);
      setActionPopup(false);
      fetchSafetyChecklistDetails();
      setClosureFiles([]);
    })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
        setActionPopup(true);
      });
  }

  // Popup Handler
  const handleChangeAction = (e) => {
    const { name, value, files } = e.target || e;
    
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setActionFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setActionRequired({
        ...actionRequired,
        [showActionPopup]: {
          ...actionRequired[showActionPopup],
          [name]: modifiedFiles.filesList,
        },
      });
    } else if ( name == "assignTo" ) {
      let assigneeName = '';
      let assigneeEmail = '';

      siteUsers.filter(v => v.userid == value).map(v => {
        assigneeName = v.fullName;
        assigneeEmail = v.email;
      });

      setActionRequired({
        ...actionRequired,
        [showActionPopup]: {
          ...actionRequired[showActionPopup],
          assigneeName: assigneeName,
          assigneeEmail: assigneeEmail,
          [name]: value
        },
      });
    } else {
      setActionRequired({
        ...actionRequired,
        [showActionPopup]: {
          ...actionRequired[showActionPopup],
          [name]: value
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSafetyChecklistDetails();
    }
  }, [id]);
  
  useEffect(() => {
    getChecklistTemplate();
    fetchSafetyChecklistAnswers();
  }, [safetyChecklist]);

  useEffect(() => {
    getTemplateSections();
  }, [template]);

  useEffect(() => {
    getTemplateItems();
  }, [template, sections]);

  useEffect( () => {
    if ( showActionPopup == false ) {
      setTimeout(() => {
        saveProgress();
      }, 500);
    }
  }, [showActionPopup]);
  
  useEffect( () => {
    if ( triggerSaveProgress == true ) {
      setTimeout(() => {
        saveProgress();
      }, 500);
    }
  }, [triggerSaveProgress]);

  useEffect(() => {
    
    if ( template && safetyChecklist && sections && templateItems && safetyChecklist.isCompleted != 1 ) {
      let newActionRequired = {};
      let fieldDataValues = {};

      if ( template.sections ) {
        sections.map((section, index) => {
          Array.isArray(templateItems[section.id]) &&
          templateItems[section.id].map(
            (item, itemIndex) => {
              newActionRequired['q_' + section.id + '_' + item.si_id] = {
                comment: '',
                status: '',
                chosenValue: template.defaultChoice == 1 ? 1 : 2,
                item
              }

              fieldDataValues['q_' + section.id + '_' + item.si_id] = template.defaultChoice == 1 ? 1 : 2;

              if ( existingAnswers['q_' + section.id + '_' + item.si_id] ) {
                newActionRequired['q_' + section.id + '_' + item.si_id] = {
                  ...newActionRequired['q_' + section.id + '_' + item.si_id],
                  comment: existingAnswers['q_' + section.id + '_' + item.si_id].comment,
                  chosenValue: existingAnswers['q_' + section.id + '_' + item.si_id].answer,
                  date: commonService.formatDate(existingAnswers['q_' + section.id + '_' + item.si_id].completionDate, "YYYY-MM-DD"),
                  status: existingAnswers['q_' + section.id + '_' + item.si_id].status,
                  actionRequired: existingAnswers['q_' + section.id + '_' + item.si_id].actionRequired,
                  existingFiles: existingAnswers['q_' + section.id + '_' + item.si_id].files
                }

                fieldDataValues['q_' + section.id + '_' + item.si_id] = existingAnswers['q_' + section.id + '_' + item.si_id].answer;
              }

              return item;
            }
          );

          return section;
        } );
      } else {
        templateItems.map( (item, itemIndex) => {
          newActionRequired['q_' + 0 + '_' + item.si_id] = {
            comment: '',
            status: '',
            chosenValue: template.defaultChoice == 1 ? 1 : 2,
            item
          }

          fieldDataValues['q_' + 0 + '_' + item.si_id] = template.defaultChoice == 1 ? 1 : 2;

          if ( existingAnswers['q_' + 0 + '_' + item.si_id] ) {
            newActionRequired['q_' + 0 + '_' + item.si_id] = {
              ...newActionRequired['q_' + 0 + '_' + item.si_id],
              comment: existingAnswers['q_' + 0 + '_' + item.si_id].comment,
              chosenValue: existingAnswers['q_' + 0 + '_' + item.si_id].answer,
              date: commonService.formatDate(existingAnswers['q_' + 0 + '_' + item.si_id].completionDate, "YYYY-MM-DD"),
              status: existingAnswers['q_' + 0 + '_' + item.si_id].status,
              actionRequired: existingAnswers['q_' + 0 + '_' + item.si_id].actionRequired,
              existingFiles: existingAnswers['q_' + 0 + '_' + item.si_id].files
            }

            fieldDataValues['q_' + 0 + '_' + item.si_id] = existingAnswers['q_' + 0 + '_' + item.si_id].answer;
          }

          return item;
        } )
      }

      if ( existingAnswers.length > 0 ) {
        fieldDataValues = {
          ...fieldDataValues,
          ...existingAnswers
        }
      }

      setFieldData({
        ...fieldData,
        ...fieldDataValues
      })
      
      setActionRequired(newActionRequired);
    }
  }, [safetyChecklist, template, sections, templateItems, existingAnswers]);

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell: {
      width: "50%",
      flexBasis: "50%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });

  const MyDoc = () => {
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: files.split(',').filter(f => f).join(','),
      }
    ];

    if ( safetyChecklist.isCompleted && safetyChecklist.verifySignature ) {
      filesBlocks.push({
        name: "Signature",
        type: 'signatures',
        files: safetyChecklist.verifySignature
      })
    }

    return (
      <PDFRenderer name={"Safety Checklist #" + id} user={user} data={data}>
        <View>
          <View>
            <Text
              style={{
                fontSize: "20px",
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              {safetyChecklist.templateName} (
              {safetyChecklist.dateStarted
                ? commonService.formatDate(
                    safetyChecklist.dateStarted,
                    "Do MMM YYYY"
                  )
                : ""}
              )
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "16px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                padding: '5px',
                backgroundColor:
                  totalPercentage < 70
                    ? "#e13a45"
                    : totalPercentage < 85
                    ? "#f18f27"
                    : "#198754",
                color: "#fff",
              }}
            >
              Overall Score: {totalPercentage}%
            </Text>
          </View>

          {template.sections == 1 ? (
            <>
              {sections.length > 0 ? (
                <>
                  {sections.map((section, index) => (
                    <>
                      <View style={{...pdfStyles.detailsWrapper, marginBottom: '20px'}}>
                        <View style={{...pdfStyles.detailsCell, width: "100%", flexBasis: "100%",padding: 0}}>
                          <View style={{backgroundColor: "#ddd",padding: "5px"}}>
                            <Text>
                              {section.name || "Section #" + section.id}
                            </Text>
                          </View>
                          <View>
                            {templateItems.length > 0 ? (
                              <>
                                {Array.isArray(templateItems[section.id]) &&
                                  templateItems[section.id].map(
                                    (item, itemIndex) => (
                                      <>
                                        <View style={{display: "flex",flexDirection: "row",flexWrap: "wrap",padding: '5px', backgroundColor: ( itemIndex % 2 != 0 ? '#f3f3f3' : '#fff' )}}>
                                          <View style={{width: '70%', flexBasis: '70%'}}>
                                            <Text>{item.si_description}</Text>

                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].comment &&
                                            <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                              <Text>Comment: </Text>
                                              <Text>{safetyChecklistAnswers[section.id + '_' + item.si_id ].comment}</Text>
                                            </View>
                                            }

                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].status &&
                                            <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                              <Text>Status: </Text>
                                              <Text>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken ? 'Closed' : safetyChecklistAnswers[section.id + '_' + item.si_id ].status}</Text>
                                            </View>
                                            }

                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].actionRequired &&
                                            <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                              <Text>Action Required: </Text>
                                              <Text>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionRequired}</Text>
                                            </View>
                                            }
                                            
                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken &&
                                            <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                              <Text>Action Taken: </Text>
                                              <Text>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken}</Text>
                                            </View>
                                            }

                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTakenOn &&
                                            <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                              <Text>Action Taken On: </Text>
                                              <Text>{commonService.formatDate(safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTakenOn, "Do MMM YYYY")}</Text>
                                            </View>
                                            }

                                            {safetyChecklistAnswers[section.id + '_' + item.si_id ] && safetyChecklistAnswers[section.id + '_' + item.si_id ].closureFiles && <>
                                              <PDFFiles
                                                block={{
                                                  name: "Closure Files",
                                                  files: safetyChecklistAnswers[section.id + '_' + item.si_id ].closureFiles.split(",").filter((v) => v).join(",")
                                                }}
                                              />
                                            </>}

                                          </View>
                                          {safetyChecklist.isCompleted == 1 ? (
                                            <View style={{width: '30%', flexBasis: '30%',textAlign: 'right'}}>
                                              <Text>
                                                {typeof safetyChecklistAnswers[
                                                  section.id + "_" + item.si_id
                                                ] == "undefined" ||
                                                safetyChecklistAnswers[
                                                  section.id + "_" + item.si_id
                                                ].answer == 2
                                                  ? "N/A"
                                                  : safetyChecklistAnswers[
                                                      section.id +
                                                        "_" +
                                                        item.si_id
                                                    ].answer == 1
                                                  ? template.scoringSystem == 1
                                                    ? "Pass"
                                                    : "Yes"
                                                  : template.scoringSystem == 1
                                                  ? "Fail"
                                                  : "No"}
                                              </Text>
                                            </View>
                                          ) : null}
                                        </View>
                                      </>
                                    )
                                  )}
                              </>
                            ) : (
                              <>
                                <Text>Safety Checklist items not found</Text>
                              </>
                            )}
                          </View>

                          {sectionScores[section.id] && (
                            <>
                              <View

                              style={{
                                paddingLeft: '5px',
                              }}
                              >
                                <Text
                                  style={{
                                    fontSize: "11px",
                                    fontFamily: "Helvetica-Bold",
                                    textAlign: "left",
                                    padding: '5px',
                                    width: '150px',
                                    backgroundColor:
                                      sectionScores[section.id].percentage < 70
                                        ? "#e13a45"
                                        : sectionScores[section.id].percentage < 85
                                        ? "#f18f27"
                                        : "#198754",
                                    color: "#fff",
                                  }}
                                >
                                  Section Score:{" "}
                                  {sectionScores[section.id].percentage}
                                </Text>
                              </View>
                            </>
                          )}
                        </View>
                      </View>
                    </>
                  ))}
                </>
              ) : null}
            </>
          ) : (
            <>
              {templateItems.length > 0 ? (
                <>
                  <View>
                    <View style={pdfStyles.detailsWrapper}>
                      {Array.isArray(templateItems) &&
                        templateItems.map((item, itemIndex) => (
                          <>
                            <View style={{...pdfStyles.detailsCell, width: '70%', flexBasis: '70%'}}>
                              <Text>
                                <Text style={{ fontFamily: "Helvetica" }}>
                                  Item:{" "}
                                </Text>
                                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                                  {item.si_description}
                                </Text>
                              </Text>

                              {safetyChecklistAnswers['0' + '_' + item.si_id ].comment &&
                              <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                <Text>Comment: </Text>
                                <Text>{safetyChecklistAnswers['0' + '_' + item.si_id ].comment}</Text>
                              </View>
                              }

                              {safetyChecklistAnswers['0' + '_' + item.si_id ].status &&
                              <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                <Text>Status: </Text>
                                <Text>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken ? 'Closed' : safetyChecklistAnswers['0' + '_' + item.si_id ].status}</Text>
                              </View>
                              }
                              
                              
                              {safetyChecklistAnswers['0' + '_' + item.si_id ].actionRequired &&
                              <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                <Text>Action Required: </Text>
                                <Text>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionRequired}</Text>
                              </View>
                              }

                              {safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken &&
                              <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                <Text>Action Taken: </Text>
                                <Text>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken}</Text>
                              </View>
                              }

                              {safetyChecklistAnswers['0' + '_' + item.si_id ].actionTakenOn &&
                              <View style={{backgroundColor: '#e5e5e5', padding: '5px', marginTop: '10px'}}>
                                <Text>Action Taken On: </Text>
                                <Text>{commonService.formatDate(safetyChecklistAnswers['0' + '_' + item.si_id ].actionTakenOn, "Do MMM YYYY")}</Text>
                              </View>
                              }

                              {safetyChecklistAnswers[0 + '_' + item.si_id ].closureFiles && <>
                                  <PDFFiles
                                    block={{
                                      name: "Closure Files",
                                      files: safetyChecklistAnswers[0 + '_' + item.si_id ].closureFiles.split(",").filter((v) => v).join(",")
                                    }}
                                  />
                                </>}

                            </View>

                            {safetyChecklist.isCompleted == 1 ? (
                              <View style={{...pdfStyles.detailsCell, width: '30%', flexBasis: '30%', textAlign: 'right'}}>
                                <Text>
                                  <Text style={{ fontFamily: "Helvetica" }}>
                                    Answer:{" "}
                                  </Text>
                                  <Text
                                    style={{ fontFamily: "Helvetica-Bold" }}
                                  >
                                    {typeof safetyChecklistAnswers[
                                      "0" + "_" + item.si_id
                                    ] == "undefined" ||
                                    safetyChecklistAnswers[
                                      "0" + "_" + item.si_id
                                    ].answer == 2
                                      ? "N/A"
                                      : safetyChecklistAnswers[
                                          "0" + "_" + item.si_id
                                        ].answer == 1
                                      ? template.scoringSystem == 1
                                        ? "Pass"
                                        : "Yes"
                                      : template.scoringSystem == 1
                                      ? "Fail"
                                      : "No"}
                                  </Text>
                                </Text>
                              </View>
                            ) : null}
                          </>
                        ))}
                    </View>

                    {sectionScores[0] && (
                      <View
                        style={{
                          marginLeft: '5px',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "11px",
                            fontFamily: "Helvetica-Bold",
                            textAlign: "left",
                            padding: '5px',
                            width: '150px',

                            backgroundColor:
                              totalPercentage < 70
                                ? "#e13a45"
                                : totalPercentage < 85
                                ? "#f18f27"
                                : "#198754",
                            color: "#fff",
                          }}
                        >
                          Total Score: {sectionScores[0].percentage}%
                        </Text>
                      </View>
                    )}
                  </View>
                </>
              ) : null}
            </>
          )}

        </View>
        
        <View style={pdfStyles.detailsWrapper}>
          <View style={{
            width: "100%",
            flexBasis: "100%",
            padding: "5px",
            borderRight: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            marginTop: "10px"
          }}>
            <Text>
              <Text style={{ fontFamily: "Helvetica" }}>
                Additional Comments
              </Text>
              <Text style={{ fontFamily: "Helvetica-Bold" }}>
                {safetyChecklist?.additionalComments}
              </Text>
            </Text>
          </View>
        </View>

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
      </PDFRenderer>
    );
  };

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-checklist/" + deleteId)
        .then(function (response) {
          toast.success("Safety Checklist archived successfully");
          window.location = '/safety-checklists';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Safety Checklist"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Checklists", path: "/safety-checklists/" },
          { name: "Safety Checklist", path: `/safety-checklists/${id}` },
        ]}
      />

        <CustomPopup
          trigger={actionPopup}
          setTrigger={() => {
            setActionPopup(false)
            setPopupData({
              actionTaken: '',
              closureFiles: null,
            });
            setClosureFiles([]);
          }}
        >
          <div className="general_details_fields justify-content-start flex-wrap">
            <div>
              <div className="col-12 form_inner p-2">
                <label for="">
                  Action Taken <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="actionTaken"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      cols="100"
                      onChange={(e) => setPopupData({
                        ...popupData,
                        actionTaken: e.target.value
                      })}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>

              <div className="">
                <div className="d-flex flex-wrap">
                  {!!closureFiles.length && closureFiles.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>))}
                </div>
                <div className="select_files">
                  <input
                    name="files"
                    type="file"
                    multiple
                    id="upload"
                    hidden
                    onChange={(e) => {
                      const { name, value, files } = e.target || e;
                      Promise.all(Array.from(e.target.files).map(file => getBase64(file)))
                        .then((result) => {
                          setClosureFiles(result);
                        })
                        .catch((err) => {
                        });
                      setPopupData({
                        ...popupData,
                        closureFiles: files
                      });
                    }}
                  />
                  <label for="upload">
                    <img src={fileIcon} alt="Icon" />
                    Select files...
                  </label>
                </div>
                <div className="select_all_files">
                  Select all files you wish to upload in one go and NOT individually.
                </div>
                <div className="hold_down">
                  Hold down CTRL (CMD on Mac) to select multiple files.
                  {error.files && (
                    <span className="err">{error.files}</span>
                  )}
                </div>
              </div>

              <div className="sign_save_reset_btn">
                <button
                  className="btn btn-success"
                  onClick={handleActionTaken}
                >
                  Submit
                </button>
              </div>

            </div>
          </div>
        </CustomPopup>

        <CustomPopup
          trigger={detailsPopup}
          setTrigger={() => {
            setDetailsPopup(false)
            setDetailsData({
              
            });
          }}
        >
          <div className="general_details_fields justify-content-start flex-wrap">
            <div>
              <div className="d-flex">
                {detailsData.answer == 0 ? <>
                  <div className="col-md-6">

                  </div>
                </> : <>
                  <div>
                    
                  </div>
                </>}
              </div>
            </div>
          </div>
        </CustomPopup>

        <CustomPopup
          trigger={showActionPopup}
          setTrigger={() => {
            setShowActionPopup(false)
            setPopupData({
            });
          }}
        >
          <div className="general_details_fields justify-content-start flex-wrap">
            {actionRequired[showActionPopup]?.chosenValue == 1 ? <>
              <div>
                <div className="col-12 form_inner p-2">
                  <label for="">
                    Comment <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="comment"
                        maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                        rows="5"
                        cols="100"
                        value={actionRequired[showActionPopup]?.comment}
                        onChange={handleChangeAction}
                      ></textarea>
                    </div>
                  </div>
                  <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                </div>
              </div>
            </>  : <>
              <div className="col-6  p-2 location_towercrane">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Checklist Description <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      disabled
                      name="component"
                      value={actionRequired[showActionPopup]?.item.si_description}
                      onChange={handleChangeAction}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6  p-2">
                <label for="">
                  <div className="require-label">
                    Assign To <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChangeAction}
                      placeholder="Select User"
                      options={siteUsers.map(v => ({ label: v.fullName + ' - ' + (v.contractorName ? v.contractorName : v.companyName) + ' - ' + (v.roleName ? v.roleName : v.role ), value: `${v.userid}`, name: "assignTo" }))}
                        value={siteUsers.filter(v => `${v.userid}` == actionRequired[showActionPopup]?.assignTo).map(v => ({ label: v.fullName + ' - ' + (v.contractorName ? v.contractorName : v.companyName) + ' - ' + (v.roleName ? v.roleName : v.role ), value: `${v.userid}`, name: "assignTo" }))}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-12 form_inner p-2">
                  <label for="">
                    Action Required <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="action"
                        maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                        rows="5"
                        cols="100"
                        value={actionRequired[showActionPopup]?.action}
                        onChange={handleChangeAction}
                      ></textarea>
                    </div>
                  </div>
                  <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                </div>
              </div>
              <div className="col-6 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Estimated Completion Date <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input value={actionRequired[showActionPopup]?.date} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChangeAction} name="date" />
                  </div>
                </div>
              </div>
              <div className="col-6  p-2">
                <label for="">
                  <div className="require-label">
                    Status <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChangeAction}
                      placeholder="Select Status"
                      options={["Open", "In Progress", "Closed"].map(v => ({ label: v, value: v, name: "status" }))}
                      value={actionRequired[showActionPopup]?.status ? { label: actionRequired[showActionPopup]?.status, value: actionRequired[showActionPopup]?.status } : null}
                    />
                  </div>
                </div>
              </div>
            </>}
            
            <div className="">
              <div className="d-flex">
                {!!actionFiles.length && actionFiles.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                  <img
                    className="obj_fit_img obj-fit-cover rounded"
                    src={imgPreviewUrl}
                    alt=""
                    height="150"
                    width="150"
                  />
                </div>))}
              </div>
              <div className="select_files">
                <input
                  name="files"
                  type="file"
                  multiple
                  id="upload"
                  hidden
                  onChange={handleChangeAction}
                />
                <label for="upload">
                  <img src={fileIcon} alt="Icon" />
                  Select files...
                </label>
              </div>
              <div className="select_all_files">
                Select all files you wish to upload in one go and NOT individually.
              </div>
              <div className="hold_down">
                Hold down CTRL (CMD on Mac) to select multiple files.
                {error.files && (
                  <span className="err">{error.files}</span>
                )}
              </div>
            </div>
          </div>

          <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              let chosenValues = actionRequired[showActionPopup];
              let newSafetychecklistAnswers = { ...safetyChecklistAnswers };
              
              if ( chosenValues.item && typeof chosenValues.item.sti_sectionId !== 'undefined' ) {
                let answerKey = ( chosenValues.item.sti_sectionId || '0' ) + '_' + chosenValues.item.si_id;
                
                if ( chosenValues.chosenValue == 1 ) {
                  chosenValues.status = '';
                  chosenValues.date = '';
                  chosenValues.assignTo = '';
                  chosenValues.action = '';
                } else {
                  chosenValues.comment = '';
                }
                
                newSafetychecklistAnswers[answerKey] = {
                  ...newSafetychecklistAnswers[answerKey],
                  assignTo: chosenValues.assignTo,
                  comment: chosenValues.comment,
                  status: chosenValues.status,
                  actionRequired: chosenValues.action,
                  date: chosenValues.date
                }
              }

              setSafetyChecklistAnswers(newSafetychecklistAnswers);

              setShowActionPopup(false)
            }}
          >
            Submit
          </button>
        </div>

        </CustomPopup>
        
        

      {!loading && safetyChecklist.id && templateItems.length > 0 ? (
        Object.keys(data).length && safetyChecklist.id ? (
          <>
            <div className="main_container">
              <h3>{safetyChecklist.templateName} <small>({safetyChecklist.dateStarted
                ? commonService.formatDate(safetyChecklist.dateStarted, "Do MMM YYYY")
                : ""})</small></h3>
              
              {safetyChecklist.isCompleted == 1 && <div>
                <div className={ ( totalPercentage < 70 ? 'red-bg' : ( totalPercentage < 85 ? 'yellow-bg' : 'green-bg' ) ) + ' section-score' }>
                  Overall Score: {totalPercentage}%
                </div>
              </div>}
            </div>

            <div className="main_container" style={{marginTop: "20px"}}>
              {template.sections == 1 ? (
                <>
                  {sections.length > 0 ? (
                    <>
                      {sections.map((section, index) => (
                        <>
                          <div
                            className="addaccess_first_container checklists-section"
                            id={section.id}
                            key={index}
                          >
                            <>
                              <div className="general_details checklists-section-title">
                                {section.name || "Section #" + section.id}
                              </div>
                              <div className="checklists-questions">
                                {templateItems.length > 0 ? (
                                  <>
                                      
                                    {Array.isArray(templateItems[section.id]) &&
                                      templateItems[section.id].map(
                                        (item, itemIndex) => (

                                          <>
                                            <div
                                              className="checklists-question"
                                            >
                                              <div className="checklists-question-text">
                                                <div>{item.si_description}</div>
                                                {safetyChecklistAnswers[section.id + '_' + item.si_id ] && <>
                                                  {safetyChecklistAnswers[section.id + '_' + item.si_id ].comment &&
                                                  <div className="checklist-question-other-detail-item">
                                                    <div>Comment: </div>
                                                    <div>{safetyChecklistAnswers[section.id + '_' + item.si_id ].comment}</div>
                                                  </div>
                                                  }

                                                  {safetyChecklistAnswers[section.id + '_' + item.si_id ].status &&
                                                  <div className="checklist-question-other-detail-item">
                                                    <div>Status: </div>
                                                    <div>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken ? 'Closed' : safetyChecklistAnswers[section.id + '_' + item.si_id ].status}</div>
                                                  </div>
                                                  }

                                                  {safetyChecklistAnswers[section.id + '_' + item.si_id ].actionRequired &&
                                                  <div className="checklist-question-other-detail-item">
                                                    <div>Action Required: </div>
                                                    <div>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionRequired}</div>
                                                  </div>
                                                  }
                                                  
                                                  {safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken &&
                                                  <div className="checklist-question-other-detail-item">
                                                    <div>Action Taken: </div>
                                                    <div>{safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken}</div>
                                                    {safetyChecklistAnswers[section.id + '_' + item.si_id ].closureFiles && <>
                                                      <div className="checklist-question-closure-files-title">Files Added to Closure</div>
                                                      <div className="checklist-question-closure-files">
                                                        {safetyChecklistAnswers[section.id + '_' + item.si_id ].closureFiles.split(",").filter((v) => v).map((imgPreviewUrl) => (
                                                        <div className="checklist-question-closure-file">
                                                          <div
                                                              id="profile_pic_1_preview"
                                                              className="image-fixed m-2"
                                                            >
                                                              {commonService.outputImageTile(imgPreviewUrl)}
                                                            </div>
                                                        </div> 
                                                        ))}
                                                      </div>
                                                    </>}
                                                  </div>
                                                  }

                                                  {safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTakenOn &&
                                                  <div className="checklist-question-other-detail-item">
                                                    <div>Action Taken On: </div>
                                                    <div>{commonService.formatDate(safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTakenOn, "Do MMM YYYY")}</div>
                                                  </div>
                                                  }
                                                </>}
                                              </div>
                                              {safetyChecklist.isCompleted == 1 ? <>
                                                <div className="checklists-question-answers">
                                                  {
                                                    typeof safetyChecklistAnswers[section.id + '_' + item.si_id ] == 'undefined' || 
                                                      safetyChecklistAnswers[section.id + '_' + item.si_id ].answer == 2 ? 'N/A' :
                                                      
                                                      ( safetyChecklistAnswers[section.id + '_' + item.si_id ].answer == 1 ?
                                                        ( template.scoringSystem == 1 ? 'Pass' : 'Yes' ) :
                                                        ( template.scoringSystem == 1 ? 'Fail' : 'No')
                                                      )
                                                  }

                                                  { (
                                                      typeof safetyChecklistAnswers[section.id + '_' + item.si_id ] == 'undefined' || 
                                                      (safetyChecklistAnswers[section.id + '_' + item.si_id ].answer == 0) && ! safetyChecklistAnswers[section.id + '_' + item.si_id ].actionTaken) && <>
                                                        <button
                                                        className="btn btn-success"
                                                        style={{ marginLeft: "10px" }}
                                                        onClick={() => {
                                                          setActionPopup(true);
                                                          setPopupData({ ...popupData, itemId: item.sti_itemId, sectionId: section.id });
                                                        }}>
                                                          Take Action
                                                        </button>
                                                      </> }
                                                </div>
                                              </> : <>
                                                <div className="checklists-question-radios">
                                                  <FormControl>
                                                    <RadioGroup
                                                      row
                                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                                      name={'q_' + section.id + '_' + item.si_id}
                                                      onChange={e => handleChange(e, item)}
                                                    >
                                                      <FormControlLabel
                                                        checked={typeof fieldData['q_' + section.id + '_' + item.si_id ] !== 'undefined' && fieldData[ 'q_' + section.id + '_' + item.si_id ] == 1}
                                                        
                                                        value={1}
                                                        control={<Radio />}
                                                        label={template.scoringSystem == 1 ? 'Pass' : 'Yes' }
                                                      />
                                                      <FormControlLabel
                                                        checked={typeof fieldData[ 'q_' + section.id + '_' + item.si_id ] !== 'undefined' && fieldData[ 'q_' + section.id + '_' + item.si_id ] == 0}
                                                        value={0}
                                                        control={<Radio />}
                                                        label={template.scoringSystem == 1 ? 'Fail' : 'No' }
                                                      />
                                                      <FormControlLabel
                                                        checked={typeof fieldData[ 'q_' + section.id + '_' + item.si_id ] !== 'undefined' && fieldData[ 'q_' + section.id + '_' + item.si_id ] == 2}
                                                        value={2}
                                                        control={<Radio />}
                                                        label="N/A"
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </div>
                                              </>}
                                            </div>
                                          </>

                                        )
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <div>Safety Checklist items not found</div>
                                  </>
                                )}
                              </div>

                              {sectionScores[section.id] && <>
                                <div className={ ( sectionScores[section.id].percentage < 70 ? 'red-bg' : ( sectionScores[section.id].percentage < 85 ? 'yellow-bg' : 'green-bg' ) ) + ' section-score' }>
                                  Section Score: {sectionScores[section.id].percentage}
                                </div>
                              </>}
                            </>
                          </div>
                        </>
                      ))}
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {templateItems.length > 0 ? (
                    <>
                      <div
                        className="addaccess_first_container checklists-section"
                        id={0}
                        key={0}
                      >
                        <div className="checklists-questions">
                        {Array.isArray(templateItems) &&
                          templateItems.map((item, itemIndex) => (

                            <>
                              
                              <div
                                className="checklists-question"
                              >
                                <div className="checklists-question-text">
                                  <div>{item.si_description}</div>
                                    {safetyChecklistAnswers['0' + '_' + item.si_id ] && <>
                                      {safetyChecklistAnswers['0' + '_' + item.si_id ].comment &&
                                      <div className="checklist-question-other-detail-item">
                                        <div>Comment: </div>
                                        <div>{safetyChecklistAnswers['0' + '_' + item.si_id ].comment}</div>
                                      </div>
                                      }

                                      {safetyChecklistAnswers['0' + '_' + item.si_id ].status &&
                                      <div className="checklist-question-other-detail-item">
                                        <div>Status: </div>
                                        <div>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken ? 'Closed' : safetyChecklistAnswers['0' + '_' + item.si_id ].status}</div>
                                      </div>
                                      }
                                      
                                      
                                      {safetyChecklistAnswers['0' + '_' + item.si_id ].actionRequired &&
                                      <div className="checklist-question-other-detail-item">
                                        <div>Action Required: </div>
                                        <div>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionRequired}</div>
                                      </div>
                                      }

                                      {safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken &&
                                      <div className="checklist-question-other-detail-item">
                                        <div>Action Taken: </div>
                                        <div>{safetyChecklistAnswers['0' + '_' + item.si_id ].actionTaken}</div>

                                        {safetyChecklistAnswers['0' + '_' + item.si_id ].closureFiles && <>
                                          <div className="checklist-question-closure-files-title">Files Added to Closure</div>
                                          <div className="checklist-question-closure-files">
                                            {safetyChecklistAnswers['0' + '_' + item.si_id ].closureFiles.split(",").filter((v) => v).map((imgPreviewUrl) => (
                                              <div className="checklist-question-closure-file">
                                                <div
                                                  id="profile_pic_1_preview"
                                                  className="image-fixed m-2"
                                                >
                                                  {commonService.outputImageTile(imgPreviewUrl)}
                                                </div>
                                              </div> 
                                            ))}
                                          </div>
                                        </>}
                                      </div>
                                      }

                                      {safetyChecklistAnswers['0' + '_' + item.si_id ].actionTakenOn &&
                                      <div className="checklist-question-other-detail-item">
                                        <div>Action Taken On: </div>
                                        <div>{commonService.formatDate(safetyChecklistAnswers['0' + '_' + item.si_id ].actionTakenOn, "Do MMM YYYY")}</div>
                                      </div>
                                      }
                                    </>}
                                </div>
                                {safetyChecklist.isCompleted == 1 ? <>
                                  <div className="checklists-question-answers" data-key={'0' + '_' + item.si_id }>
                                    {
                                      typeof safetyChecklistAnswers['0' + '_' + item.si_id ] !== 'undefined' && 
                                        safetyChecklistAnswers['0' + '_' + item.si_id ].answer == 2 ? 'N/A' :
                                        
                                        ( safetyChecklistAnswers['0' + '_' + item.si_id ].answer == 1 ?
                                          ( template.scoringSystem == 1 ? 'Pass' : 'Yes' ) :
                                          ( template.scoringSystem == 1 ? 'Fail' : 'No')
                                        )
                                    }

                                    { (
                                      typeof safetyChecklistAnswers[0 + '_' + item.si_id ] == 'undefined' || 
                                      (safetyChecklistAnswers[0 + '_' + item.si_id ].answer == 0) && ! safetyChecklistAnswers[0 + '_' + item.si_id ].actionTaken) && <>
                                        <button
                                        className="btn btn-success"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => {
                                          setActionPopup(true);
                                          setPopupData({ ...popupData, itemId: item.sti_itemId, sectionId: 0 });
                                        }}>
                                          Take Action
                                        </button>
                                      </> }

                                  </div>
                                </> : <>
                                  <div className="checklists-question-radios">
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name={'q_' + 0 + '_' + item.si_id}
                                        onChange={e => handleChange(e, item)}
                                      >
                                        <FormControlLabel
                                          checked={typeof fieldData['q_' + 0 + '_' + item.si_id ] == 'undefined' || fieldData[ 'q_' + 0 + '_' + item.si_id ] == 1}
                                          
                                          value={1}
                                          control={<Radio />}
                                          label={template.scoringSystem == 1 ? 'Pass' : 'Yes' }
                                        />
                                        <FormControlLabel
                                          checked={typeof fieldData[ 'q_' + 0 + '_' + item.si_id ] !== 'undefined' && fieldData[ 'q_' + 0 + '_' + item.si_id ] == 0}
                                          value={0}
                                          control={<Radio />}
                                          label={template.scoringSystem == 1 ? 'Fail' : 'No' }
                                        />
                                        <FormControlLabel
                                          checked={typeof fieldData[ 'q_' + 0 + '_' + item.si_id ] !== 'undefined' && fieldData[ 'q_' + 0 + '_' + item.si_id ] == 2}
                                          value={2}
                                          control={<Radio />}
                                          label="N/A"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                  </> }
                              </div>
                          </>
                          ))}
                        </div>

                        {sectionScores[0] && <>
                            <div className={ ( sectionScores[0].percentage < 70 ? 'red-bg' : ( sectionScores[0].percentage < 85 ? 'yellow-bg' : 'green-bg' ) ) + ' section-score' }>
                              Total Score: {sectionScores[0].percentage}%
                            </div>
                          </>}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>Safety Checklist items not found</div>
                    </>
                  )}
                </>
              )}

              <div className="p-2"></div>
              {id && safetyChecklist.isCompleted == 1 && userPermissions.includes('submitsafetyChecklists') && data.isDeleted != 1 ? <>
                <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                  Archive
                </button>
              </> : ''}
              <div className="p-2"></div>

              <div className="addaccess_second_container">
                <div className="general_details">Additional Comments</div>
                <div className="flex flex-wrap">
                  
                  <div className="col-8">
                    <div className="form_inner p-2 w-100">
                      <div className="input_icons">
                        <div className="input_field">
                          <textarea
                            name="additionalComments"
                            maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                            rows="5"
                            disabled={safetyChecklist.isCompleted == 1 ? true : false}
                            className="w-100"
                            cols="100"
                            value={id ? safetyChecklist.additionalComments : fieldData.additionalComments}
                            onChange={handleChange}
                            placeholder="Enter any additional information here"
                          ></textarea>
                        </div>
                      </div>
                      <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="addaccess_sixth_container mb-4">
                <div className="general_details">Signature(s)</div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="p-2">
                      {safetyChecklist.isCompleted == 1 ? <>
                        {safetyChecklist.verifySignature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              safetyChecklist.verifySignature
                                ? process.env.REACT_APP_API_BASEURL +
                                safetyChecklist.verifySignature
                                : DefaultImage
                            }
                          ></img> : null}
                      </> : <>
                        <div className="sign_image submitter_signature">
                          <SignatureCanvas
                            ref={signatureVerifyRef}
                            canvasProps={{ width: 263, height: 270 }}
                          />
                          <span
                            className="cancel-custom cursor-pointer"
                            onClick={handleClearVerifySign}
                          >
                            <i
                              className="clear_btn  text-danger fa fa-times-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </>}
                    </div>
                  </div>
                  <div className="col-md-4">
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex flex-wrap">
                    {files && 
                      files.split(",").filter((v) => v).map((imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          {commonService.outputImageTile(imgPreviewUrl)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {safetyChecklist.isCompleted != 1 && <>
                <div className="mt-3" style={{marginBottom: '80px'}}>
                  {!loading ? (
                    <>
                      <button
                        className="btn btn-success me-2"
                        onClick={() => handleSubmit()}
                      >
                        {id ? "Submit" : ''}
                      </button>

                      {/* <div className="save-progress-button">
                        <button
                          className="btn btn-success me-2"
                          onClick={() => saveProgress()}
                        >
                          {id ? "Save Progress" : ''}
                        </button>
                      </div> */}
                    </>
                  ) : (
                    <button
                      type="button"
                      style={{ width: "53px" }}
                      class="btn btn-success me-2"
                    >
                      <Spinner size="sm" animation="border" variant="light" />
                    </button>
                  )}

                  <button className="btn btn-secondary me-2">Reset</button>
                  <span>or</span>
                  <button className="cancel_btn">cancel</button>
                  <span className="float-end">required fields</span>
                  <span className="text-danger float-end">*</span>
                </div>
              </>}



              {safetyChecklist.isCompleted == 1 &&
                (user.role.includes("admin") ||
                  (
                    siteUsers
                    .filter((v) => v.userid == user.id).length && siteUsers
                    .filter((v) => v.userid == user.id)[0].type == 'admin'
                  ) ||
                  user.id == data.addedByInCompany) && (
                    <>
                      <PDFDownloadLink
                        document={<MyDoc />}
                        className="mt-2 btn btn-primary pdf-download-btn"
                        fileName={"safety-checklist-" + id + ".pdf"}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download PDF"
                        }
                      </PDFDownloadLink>
                      </>
               )}
            </div>

          </>
        ) : (
          "Unable To Fetch Data"
        )
      ) : (
        "Loading"
      )}

    </div>
  );
}
