import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./GA1RoTE.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { commonService } from "../common/common.service";

const AddGA1RoTE = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    supervisor: null,
    locationOfWorks: null,
    startDate: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    finishDate: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    dateOnSite: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    verifySignature: null,
    resubmitComment: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, startDate: null, finishDate: null, dateOnSite: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchGA1RoTEDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ga1rote/permit/details`,
        {
          ga1roteId: id,
          companyId: fieldData.companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id && companyId) {
      fetchGA1RoTEDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    if ( user.role == 'superadmin' ) {
      setUserID(selectedCompany);
    }
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "supervisor":
        if (!value) {
          message = stateObj[name] = "Serial Number is required.";
        }
        break;
      case "locationOfWorks":
        if (!value) {
          message = stateObj[name] = "Type of plant is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "Start Date":
        if (!value) {
          message = stateObj[name] = "Start Date is required.";
        }
        break;
      case "Finish Date":
        if (!value) {
          message = stateObj[name] = "Finish Date is required.";
        }
        break;
      case "dateOnSite":
        if (!value) {
          message = stateObj[name] = "Date On Site is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "files":
        if (!value) {
          message = stateObj[name] = "Upload At least 1 file.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign(id)])
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      console.log(error)
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      locationOfWorks: fieldData.locationOfWorks,
      supervisor: fieldData.supervisor,
      contractorId: user.contractorId||user.role=="admin"?fieldData.contractorId:true,
      startDate: fieldData.startDate,
      finishDate: fieldData.finishDate,
      dateOnSite: fieldData.dateOnSite,
      verifySignature: id ? verifySignature : true,
      files: !id ? (files && files.length) : true
    };
    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.supervisor !== null &&
      fieldData.locationOfWorks !== null &&
      (user.contractorId||user.role=="admin"?fieldData.contractorId !== null:true) &&
      fieldData.startDate !== null &&
      fieldData.finishDate !== null &&
      fieldData.dateOnSite !== null &&
      (!id ? files && files.length : true) &&
      (id ? verifySignature !== null : true)
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("ga1rote") : v.permits.includes("ga1rotereceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("supervisor", fieldData.supervisor);
      form.append("locationOfWorks", fieldData.locationOfWorks);
      form.append("contractorId", fieldData.contractorId);
      form.append("startDate", fieldData.startDate);
      form.append("finishDate", fieldData.finishDate);
      form.append("dateOnSite", fieldData.dateOnSite);
      if (id) {
        form.append("verifySignature", verifySignature);
      }
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });

      // QRCode Specific Data
      form.append( 'userCompanyLogo', (user.companyLogo || user.contractorLogo) ? (process.env.REACT_APP_API_BASEURL + (user.contractorLogo ? user.contractorLogo : user.companyLogo)) : 'uploads/foresight-logo.png') ;

      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/ga1rote-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/ga1rote-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/view-own/ga1rote-permits')
        setFieldData(fieldData);
        if (id){
          handleClearVerifySign();
        }
      })
        .catch((err) => {
          console.log(err)
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = (id) => {
    return new Promise((res, rej) => {
      if ((signatureVerifyRef.current && signatureVerifyRef.current.isEmpty())||!id) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3  p-2 location_access">
              <label for="">
                <div className="require-label">
                  {" "}
                  Type of Plant <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="locationOfWorks"
                    value={fieldData.locationOfWorks}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.locationOfWorks && (
                <span className="err">{error.locationOfWorks}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Current Test Date  <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.startDate}  type="date" onChange={handleChange} name="startDate" />
                </div>
              </div>
              {error.startDate && <span className="err">{error.startDate}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Next Test Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishDate} type="date" onChange={handleChange} name="finishDate" />
                </div>
              </div>
              {error.finishDate && <span className="err">{error.finishDate}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Date On Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.dateOnSite} disabled={!!id} type="date" onChange={handleChange} name="dateOnSite" />
                </div>
              </div>
              {error.dateOnSite && <span className="err">{error.dateOnSite}</span>}
            </div>
            <div className="col-3  p-2 location_access">
              <label for="">
                <div className="require-label">
                  {" "}
                  Serial Number <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="supervisor"
                    value={fieldData.supervisor}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.supervisor && (
                <span className="err">{error.supervisor}</span>
              )}
            </div>
        
          </div>
        </div>
      
        
        <div className="addaccess_fifth_container">
          <div className="general_details">Upload Files & Certificates <span className="text-danger"> *</span></div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>
        {id && <div className="addaccess_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="p-2">

            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>}
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddGA1RoTE;
