import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Firecheck.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import CustomPopup from "../Popup/CustomPopup";
import { commonService } from "../common/common.service";

const AddFirecheck = ({ title }) => {
  const { id } = useParams();
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const firepoints = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const questions = {
    fireplan: "Is there a up to date fire plan in place?",
    drawingLocation:
      "Is there a drawing showing the location & Number for all fire points?",
    fireDrill:
      "Has a fire/ evacuation drill been carried out in last 6 months?( IF YES PLEASE ADD DATE OF DRILL IN ADDITIONAL COMMENTS BOX BELOW)",
    fireAlarm: "Has the Fire Alarm been tested in the last week?",
    firePointClean: "Fire Points Clean and Unobstructed",
    firePointSign: "Fire Points Signs Visible",
    fireExt:
      "Are all the correct fire extinguishers in place and clearly visible.",
    extPress:
      "Extinguisher's pressure indicators in the operational range (i.e. in the green).",
    extDate: "Extinguishers in date.",
    fireEq:
      "Firefighting equipment in good working order. Seals or indicator tabs not broken.",
    sounder: "Sounder Working.",
    siteWide: "Sitewide Evacuation Plan (EB1) displayed.",
    actionRequired: "All fire points meet all fire check requirements",
  };

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    firepoints: "",
    descriptionOfWorks: null,
    date: new Date()
      .toLocaleString("en-GB")
      .substring(0, 10)
      .split("/")
      .reverse()
      .join("-"),
    ...Object.keys(questions).reduce((acc, currentValue) => {
      acc[currentValue] = 2;
      return acc;
    }, {}),
    verifySignature: null,
    resubmitComment: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
  };

  const actionValues = {};
  Object.entries(questions).map(([key, question]) => {
    actionValues[key] = {
      component: question,
      assignTo: "",
      action: "",
      date: new Date()
        .toLocaleString("en-GB")
        .substring(0, 10)
        .split("/")
        .reverse()
        .join("-"),
      status: "",
      comment: "",
      files: "",
      actionFiles: [],
    };
  });
  const [fieldData, setFieldData] = useState(fieldValues);
  const [actionRequired, setActionRequired] = useState(actionValues);
  const [files, setFiles] = useState([]);
  const [disabledQuestion, setDisabledQuestion] = useState([]);
  const [showActionPopup, setShowActionPopup] = useState("");
  const [showYesActionPopup, setShowYesActionPopup] = useState("");
  const [error, setError] = useState({
    ...fieldValues,
    contractorId: null,
    date: null,
  });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }
          
          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchFirecheckDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/firecheck/permit/details`,
        {
          firecheckId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDisabledQuestion(
          Object.keys(questions).filter(
            (v) => res.data[0][v] == 0 || res.data[0][v] == 1
          )
        );
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchFirecheckDetails();
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e, component) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }

      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else if (Object.keys(questions).includes(name)) {
      if (value == 0) {
        setShowActionPopup(name);
        setActionRequired({
          ...actionRequired,
          [name]: {
            ...actionValues[name],
            component,
          },
        });
      } else if (
        value == 1 &&
        name !== Object.keys(questions)[Object.keys(questions).length - 1]
      ) {
        setShowYesActionPopup(name);
        setActionRequired({
          ...actionRequired,
          [name]: {
            ...actionValues[name],
            component,
          },
        });
      }
      setFieldData({
        ...fieldData,
        [name]: value,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value);
      }
    }
  };

  const handleChangeAction = (e, action) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setActionRequired({
            ...actionRequired,
            [action]: {
              ...actionRequired[action],
              [name]: modifiedFiles.filesList,
              actionFiles: result,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setActionRequired({
            ...actionRequired,
            [action]: {
              ...actionRequired[action],
              [name]: modifiedFiles.filesList,
            },
          });
        });
    } else {
      setActionRequired({
        ...actionRequired,
        [action]: {
          ...actionRequired[action],
          [name]: value,
        },
      });
    }
  };

  const isValidData = (value, name) => {
    let message = "";
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Description of Works is required.";
        }
        break;
      case "firepoints":
        if (!value) {
          message = stateObj[name] = "Operator Name is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "date":
        if (!value) {
          message = stateObj[name] = "Crane Number is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign()]);
      handleSubmitAfterSave(verifySignature);
    } catch (error) {
      toast.error("Failed to save signature");
    }
  };

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      date: fieldData.date,
      contractorId:
        user.contractorId || user.role == "admin"
          ? fieldData.contractorId
          : true,
      verifySignature: verifySignature,
    };
    let trigger = true;
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message);
        trigger = false;
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.date !== null &&
      verifySignature !== null
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers
        .filter((v) => v.type == "admin")
        .map((v) => ({
          email: v.email,
          userid: v.userid,
          fullName: v.fullName,
          emailPref: v.emailPreference
            ? v.emailPreference.includes("fireChecklist")
            : v.permits.includes("fireChecklistreceiveEmails"),
          isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
        }))
        .filter((v) => v.emailPref || v.isAdminWithNoPref);
      form.append(
        "mailUpdates",
        mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
      );
      form.append(
        "notiticationUpdates",
        siteUsers
          .filter((v) => v.type == "admin")
          .filter((v) => v.userid)
          .map((v) => v.userid)
          .join(",")
      );
      form.append(
        "departmentId",
        fieldData.departmentId == null ? null : fieldData.departmentId
      );
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("descriptionOfWorks", fieldData.descriptionOfWorks);
      form.append("firepoints", fieldData.firepoints);
      form.append("contractorId", fieldData.contractorId);
      form.append("date", fieldData.date);
      if (
        Object.keys(questions).reduce((acc, currentValue) => {
          return acc && fieldData[currentValue] !== null;
        }, true)
      ) {
        form.append("completed", "true");
      }
      Object.keys(questions).map((question) => {
        form.append(question, fieldData[question]);
        Object.entries(actionRequired[question]?.files)?.map(([key, value]) => {
          form.append(`selectedFiles${[key]}`, value);
        });
      });
      form.append("verifySignature", verifySignature);
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", "");
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall = id
        ? axiosInstance.put(
            `${process.env.REACT_APP_API_BASEURL}/update-details/firecheck-permit/${id}`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
        : axiosInstance.post(
            `${process.env.REACT_APP_API_BASEURL}/add/firecheck-permit`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

      apiCall
        .then((res) => {
          handleActionSubmitAfterSave(id ? id : res.data.id);
          toast.success(res.data.message);
          setLoading(false);
          history.push("/view-own/firecheck-permits");
          setFieldData(fieldData);
          handleClearVerifySign();
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const handleActionSubmitAfterSave = (firecheckId) => {
    const form = new FormData();
    let actionWithNo = false;
    let index = 0;
    if (
      Object.keys(questions).filter(
        (v) =>
          (fieldData[v] == 0 || fieldData[v] == 1) &&
          !disabledQuestion.includes(v)
      )
    ) {
      Object.entries(actionRequired).map(([fieldKey, obj], ind) => {
        if (
          obj.component &&
          (obj.action || obj.comment) &&
          !disabledQuestion.includes(fieldKey)
        ) {
          actionWithNo = true;
          Object.entries(obj.files)?.map(([key, value]) => {
            form.append(`${fieldKey}${[key]}`, value);
          });
          Object.keys(obj).map((v) => {
            if (v != "files" && v != "actionFiles") {
              form.append(`actions[${index}][${v}]`, obj[v]);
            }
          });
          form.append(`actions[${index}][key]`, fieldKey);
          index += 1;
        }
      });
      form.append(`firecheckId`, firecheckId);

      if (actionWithNo) {
        axiosInstance
          .post(
            `${process.env.REACT_APP_API_BASEURL}/action/firecheck-permit`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
            // toast.success(res.data.message);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              err?.response?.data?.message || "Failed to save actions"
            );
            setLoading(false);
          });
      }
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }));

  const departmentOptions = allDepartment
    .filter((v) => v.siteId == fieldData.siteId)
    .map((d) => ({
      label: d.name,
      value: d.id,
      name: "departmentId",
    }));

  return (
    <div className="middle_page">
      <Header title={title} steps={[{ name: "Home", path: "/" }]} />
      {showActionPopup && (
        <CustomPopup
          trigger={showActionPopup}
          setTrigger={() => {
            setShowActionPopup(false);
            setFieldData({
              ...fieldData,
              [showActionPopup]:
                showActionPopup !==
                Object.keys(questions)[Object.keys(questions).length - 1]
                  ? 2
                  : null,
            });
          }}
        >
          <div className="addfirecheck_first_container">
            <div className="general_details_fields justify-content-start flex-wrap">
              {showActionPopup !==
              Object.keys(questions)[Object.keys(questions).length - 1] ? (
                <div className="col-6  p-2 location_firecheck">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Component <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        disabled
                        name="component"
                        value={actionRequired[showActionPopup]?.component}
                        onChange={(e) => handleChangeAction(e, showActionPopup)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-12  p-2 location_firecheck">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      List all fire point numbers and locations, and assign it
                      to management to fix.
                    </div>
                  </label>
                </div>
              )}
              <div
                className={`col-${
                  showActionPopup !==
                  Object.keys(questions)[Object.keys(questions).length - 1]
                    ? 6
                    : 12
                } p-2`}
              >
                <label for="">
                  <div className="require-label">
                    Assign To{" "}
                    {showActionPopup ==
                    Object.keys(questions)[Object.keys(questions).length - 1]
                      ? "Management"
                      : ""}
                    <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => handleChangeAction(e, showActionPopup)}
                      placeholder="Select User"
                      options={siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName ? v.contractorName : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assignTo",
                      }))}
                      value={siteUsers
                        .filter((v) => `${v.userid}` == actionRequired[showActionPopup]?.assignTo)
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignTo",
                        }))}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-12 form_inner p-2">
                  <label for="">
                    Action Required <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="action"
                        rows="5"
                        cols="100"
                        value={actionRequired[showActionPopup]?.action}
                        onChange={(e) => handleChangeAction(e, showActionPopup)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Estimated Completion Date{" "}
                    <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={actionRequired[showActionPopup]?.date}
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={(e) => handleChangeAction(e, showActionPopup)}
                      name="date"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6  p-2">
                <label for="">
                  <div className="require-label">
                    Status <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => handleChangeAction(e, showActionPopup)}
                      placeholder="Select Status"
                      options={["Open", "In Progress", "Closed"].map((v) => ({
                        label: v,
                        value: v,
                        name: "status",
                      }))}
                      value={
                        actionRequired[showActionPopup]?.status
                          ? {
                              label: actionRequired[showActionPopup]?.status,
                              value: actionRequired[showActionPopup]?.status,
                            }
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="d-flex">
                  {!!actionRequired[showActionPopup]?.actionFiles.length &&
                    actionRequired[showActionPopup]?.actionFiles.map(
                      (imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          <img
                            className="obj_fit_img obj-fit-cover rounded"
                            src={imgPreviewUrl}
                            alt=""
                            height="150"
                            width="150"
                          />
                        </div>
                      )
                    )}
                </div>
                <div className="select_files">
                  <input
                    name="files"
                    type="file"
                    multiple
                    id="upload"
                    hidden
                    onChange={(e) => handleChangeAction(e, showActionPopup)}
                  />
                  <label for="upload">
                    <img src={fileIcon} alt="Icon" />
                    Select files...
                  </label>
                </div>
                <div className="select_all_files">
                  Select all files you wish to upload in one go and NOT
                  individually.
                </div>
                <div className="hold_down">
                  Hold down CTRL (CMD on Mac) to select multiple files.
                </div>
              </div>
            </div>
          </div>
          <div className="sign_save_reset_btn">
            <button
              className="btn btn-success"
              disabled={
                !actionRequired[showActionPopup]?.component ||
                !actionRequired[showActionPopup]?.status ||
                !actionRequired[showActionPopup]?.date ||
                !actionRequired[showActionPopup]?.assignTo ||
                !actionRequired[showActionPopup]?.action
              }
              onClick={() => {
                setShowActionPopup(false);
              }}
            >
              Submit
            </button>
          </div>
        </CustomPopup>
      )}
      {showYesActionPopup && (
        <CustomPopup
          trigger={showYesActionPopup}
          setTrigger={() => {
            setShowYesActionPopup(false);
          }}
        >
          <div className="addfirecheck_first_container">
            <div className="general_details_fields justify-content-start flex-wrap">
              <div>
                <div className="col-12 form_inner p-2">
                  <label for="">Comments</label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="comment"
                        maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                        rows="5"
                        cols="100"
                        value={actionRequired[showYesActionPopup]?.comment}
                        onChange={(e) =>
                          handleChangeAction(e, showYesActionPopup)
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                </div>
              </div>
              <div className="">
                <div className="d-flex">
                  {!!actionRequired[showYesActionPopup]?.actionFiles.length &&
                    actionRequired[showYesActionPopup]?.actionFiles.map(
                      (imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          <img
                            className="obj_fit_img obj-fit-cover rounded"
                            src={imgPreviewUrl}
                            alt=""
                            height="150"
                            width="150"
                          />
                        </div>
                      )
                    )}
                </div>
                <div className="select_files">
                  <input
                    name="files"
                    type="file"
                    multiple
                    id="upload"
                    hidden
                    onChange={(e) => handleChangeAction(e, showYesActionPopup)}
                  />
                  <label for="upload">
                    <img src={fileIcon} alt="Icon" />
                    Select files...
                  </label>
                </div>
                <div className="select_all_files">
                  Select all files you wish to upload in one go and NOT
                  individually.
                </div>
                <div className="hold_down">
                  Hold down CTRL (CMD on Mac) to select multiple files.
                </div>
              </div>
            </div>
          </div>
          <div className="sign_save_reset_btn">
            <button
              className="btn btn-success"
              onClick={() => {
                setShowYesActionPopup(false);
              }}
            >
              Submit
            </button>
          </div>
        </CustomPopup>
      )}
      <div className="main_container">
        {/* <p className="redText">This must be accompanied with a method statement approved by site management. Permits may only be issued on a daily basis.</p> */}
        <div className="addfirecheck_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id
                      ? {
                          value: siteOptions.filter(
                            (v) => v.value == fieldData.siteId
                          ),
                          isDisabled: true,
                        }
                      : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id
                      ? {
                          value: departmentOptions.filter(
                            (v) => v.value == fieldData.departmentId
                          ),
                          isDisabled: true,
                        }
                      : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? (
                    <Select
                      onChange={handleChange}
                      placeholder="Select Contractor"
                      value={
                        fieldData.contractorId
                          ? contractorData
                              .filter((v) => v.id == fieldData.contractorId)
                              .map((d) => ({
                                label: d.name,
                                value: d.id,
                                name: "contractorId",
                              }))
                          : null
                      }
                      options={contractorData.map((d) => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      }))}
                    />
                  ) : fieldData.contractorId &&
                    contractorData.filter((v) => v.id == fieldData.contractorId)
                      .length ? (
                    contractorData.filter(
                      (v) => v.id == fieldData.contractorId
                    )[0].name
                  ) : (
                    user.companyName
                  )}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    value={fieldData.date}
                    disabled={!!id}
                    min={new Date()
                      .toLocaleString("en-GB")
                      .substring(0, 10)
                      .split("/")
                      .reverse()
                      .join("-")}
                    type="date"
                    onChange={handleChange}
                    name="date"
                  />
                </div>
              </div>
              {error.date && <span className="err">{error.date}</span>}
            </div>
          </div>
        </div>
        <div className="addfirecheck_fourth_container">
          <div className="general_details">Fire Management</div>

          <div className="inspected_radio_btn">
            {Object.entries(questions)
              .filter((v, i) => i >= 0 && i <= 3)
              .map(([key, question]) => (
                <div>
                  <FormControl>
                    <FormLabel class="inspected_label">{question} <span className="text-danger"> *</span></FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name={key}
                      onChange={(e) => handleChange(e, question)}
                    >
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 1}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 0}
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 2}
                        value={2}
                        control={<Radio />}
                        label="N/A"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              ))}
          </div>
        </div>
        <div className="addfirecheck_fourth_container">
          <div className="general_details">
            Ensure the following items are checked at each firepoint
          </div>

          <div className="inspected_radio_btn">
            {Object.entries(questions)
              .filter((v, i) => i >= 4 && i <= 11)
              .map(([key, question]) => (
                <div>
                  <FormControl>
                    <FormLabel class="inspected_label">{question} <span className="text-danger"> *</span></FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name={key}
                      onChange={(e) => handleChange(e, question)}
                    >
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 1}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 0}
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 2}
                        value={2}
                        control={<Radio />}
                        label="N/A"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              ))}
          </div>
        </div>
        <div className="addfirecheck_second_container">
          <div className="general_details">
            List all fire points and locations that have been checked , as per
            the above and are in good order
          </div>
          <div>
            <div className="col-8 form_inner p-2">
              {/* <label for="">
                Defects <span className="text-danger">*</span>
              </label> */}
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="firepoints"
                    rows="5"
                    cols="100"
                    value={fieldData.firepoints}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addfirecheck_fourth_container">
          <div className="general_details">
            All fire points meet all fire check requirements
          </div>

          <div className="inspected_radio_btn">
            {Object.entries(questions)
              .filter((v, i) => i >= 12)
              .map(([key, question]) => (
                <div>
                  <FormControl>
                    {/* <FormLabel class="inspected_label">
                  {question}
                </FormLabel> */}
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name={key}
                      onChange={(e) => {
                        handleChange(e, question);
                      }}
                    >
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 1}
                        value={1}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        disabled={disabledQuestion.includes(key)}
                        checked={fieldData[key] == 0}
                        value={0}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              ))}
          </div>
        </div>
        <div className="addfirecheck_second_container">
          <div className="general_details">Additional Comments</div>
          <div>
            <div className="col-8 form_inner p-2">
              {/* <label for="">
                Defects <span className="text-danger">*</span>
              </label> */}
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="descriptionOfWorks"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.descriptionOfWorks}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
            </div>
          </div>
        </div>
        <div className="addfirecheck_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length &&
              files.map((imgPreviewUrl) => (
                <div id="profile_pic_1_preview" className="image-fixed m-2">
                  <img
                    className="obj_fit_img obj-fit-cover rounded"
                    src={imgPreviewUrl}
                    alt=""
                    height="150"
                    width="150"
                  />
                </div>
              ))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
            {error.files && <span className="err">{error.files}</span>}
          </div>
        </div>
        <div className="addfirecheck_sixth_container">
          <div className="general_details">Signature(s) <span className="text-danger"> *</span></div>
          <div className="p-2">
            <p className="hold_down">
              I have read and understood my employer's risk assessment and
              method statement for the works to be carried out. I will comply
              with the Risk Assessment and Method Statement. I fully realise
              that it is my responsibility to wear and clip on my safety harness
              at unprotected edges.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddFirecheck;
