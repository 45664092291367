import React from "react";
import "./list.css";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/circle-cross.svg";
import Restore from "../../../images/undo.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import NoRecordFound from "../../common/NoRecordFound";
import { commonService } from "../../common/common.service";

import Table from "../../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../../Popup/CustomPopup";

export default function CommonSafetyObservationsList({ title, isAll, isDeleted }) {
  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (companyId) {
      getSafetyObservations();
    }
  }, [companyId, selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    userDetail();
  }, []);

  const getSafetyObservations = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/observations`,
        {
          companyId: companyId,
          userId: user.id,
          isAll: isAll,
          isDeleted: isDeleted,
          siteId: localStorage.getItem(`${user.id}-currentSite`),
          contractorId: user.role.includes("admin") ? null : user.contractorId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );

        const savedSort = commonService.loadSortData('SafetyObservations');
        if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
        }

        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  function userDetail() {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-observation/" + id)
        .then(function (response) {
          getSafetyObservations();
          toast.success("Safety Observation deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to delete Safety Observation"
          );
        });
    }
  };

  const handleUndelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to restore ${name}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/undelete-safety-observation/" + id)
        .then(function (response) {
          getSafetyObservations();
          toast.success("Safety Observation restored successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to restore Safety Observation"
          );
        });
    }
  };

  const tableHeader = [
    'ID', 'Date', 'Assigned To', 'Submitted By', 'Status', 'Type', 'Category', 'Site', 'Department', 'Contractor',
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : (d.assignedName ? d.assignedName : ''),
        submittedBy: d.submittedBy ? d.submittedBy : (d.immutableSubmittedBy ? d.immutableSubmittedBy : ''),
        observationStatus: d.observationStatus ? d.observationStatus : '',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
        contractorName: d.originalContractorName ? d.originalContractorName : ( d.originalCompanyName ? d.originalCompanyName : (d.contractorName ? d.contractorName : d.companyName)),
      })
    })

    return array;
  }

  function getActionsConvertableArrayForXls() {
    let array = [];
    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : ( d.assignedName ? d.assignedName : '' ),
        submittedBy: d.submittedBy ? d.submittedBy : (d.immutableSubmittedBy ? d.immutableSubmittedBy : ''),
        observationStatus: d.observationStatus ? d.observationStatus : '',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
        contractorName: d.originalContractorName ? d.originalContractorName : ( d.originalCompanyName ? d.originalCompanyName : (d.contractorName ? d.contractorName : d.companyName)),
        observationDetails: d.observationDetails ? d.observationDetails : '',
        actionRequired: d.actionRequired ? d.actionRequired : '',
        actionTaken: d.actionComment ? d.actionComment : '',
        actionRootCause: d.actionRootCause ? d.actionRootCause : '',
        completionDate: d.completionDate ? commonService.formatDate(d.completionDate, "Do MMM YYYY") : '',
      })
    })

    return array;
  }

  const columns = [
    {
      name: 'View',
      selector: 'view',
      width: '100px',
      cell: row => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      }
    },
    {
      name: 'Date',
      selector: 'observationDate',
      searchField: 'observationDate',
      cell: row => row.observationDate,
      sortFunction: (a, b) => {
        return a.observationDatePlain - b.observationDatePlain;
      }
    },
    {
      name: 'Assigned To',
      selector: 'assignedName',
      searchField: 'assignedName',
      cell: row => <div>{row.assignedName}</div>,
    },
    {
      name: 'Submitted By',
      selector: 'submittedBy',
      searchField: 'submittedBy',
      cell: row => <div>{row.submittedBy}</div>,
    },
    {
      name: 'Status',
      selector: 'observationStatus',
      searchField: 'observationStatusValue',
      cell: row => <div>{row.observationStatus}</div>,
    },
    {
      name: 'Type',
      selector: 'observationType',
      searchField: 'observationType',
      cell: row => <div>{row.observationType}</div>,
    },
    {
      name: 'Category',
      selector: 'observationCategory',
      searchField: 'observationCategory',
      cell: row => <div>{row.observationCategory}</div>,
    },
    {
      name: 'Site',
      selector: 'siteName',
      searchField: 'siteName',
      cell: row => <div>{row.siteName}</div>,
    },
    {
      name: 'Department',
      selector: 'departmentName',
      searchField: 'departmentName',
      cell: row => <div>{row.departmentName}</div>,
    },
    {
      name: 'Contractor',
      selector: 'contractorName',
      searchField: 'contractorName',
      cell: row => <div>{row.contractorName}</div>,
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
      column.minWidth = '200px';
    }

    // Add sorting to all columns
    if ( typeof column.sortable === 'undefined' ) {
      column.sortable = true;
    }

    if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
      column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
    }
  });

  if ( user.role.includes("admin") ) {
    columns.push({
      name: 'Action',
      sortable: false,
      selector: 'actions',
      cell: row => <div>{row.actions}</div>,
      allowOverflow: true
    });
  }

  const downloadExcel = () => {    
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArrayForXls(),
      (tableHeader.join(',') + ',Details,Action Required, Action Taken, Root Cause, Estimate Completion Date').split(','),
      title.toLowerCase().replace(' ', '-') + '.xlsx'
    );

  }
  
  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  }

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray()
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{padding: "20px 16px 30px"}}
        >
          <Table
            data={pdfTableData}
            heading={title}
          />
        </Page>
      </Document>
    );
  }

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if ( data.length > 100 ) {
      setPdfLoader(false);
    }
    const blob = await pdf((
      <PDFReadyTable />
      )).toBlob();
      saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
      setPdfLoader(true);
  };

  const CustomSortIcon = () => (
    <span className="dt-sort-icon"></span>
  )

  const createData = () => {
    let parsedData = [];

    if ( data.length == 0 ) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: <span onClick={() => { history.push(`/safety-observations/${d.id}`); }} className="lists-id-col">{d.id}</span>,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : ( d.assignedName ? d.assignedName : '' ),
        siteName: d.siteName ? d.siteName : '',
        observationStatus: d.observationStatus ? <span class={commonService.pillify(d.observationStatus)}>{d.observationStatus}</span> : '-',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        departmentName: d.departmentName ? d.departmentName : '',
        submittedBy: d.submittedBy ? d.submittedBy : (d.immutableSubmittedBy ? d.immutableSubmittedBy : ''),
        contractorName: d.originalContractorName ? d.originalContractorName : ( d.originalCompanyName ? d.originalCompanyName : (d.contractorName ? d.contractorName : d.companyName)),

        observationStatusValue: d.observationStatus ? d.observationStatus : '',

        observationDatePlain: d.observationDate ? commonService.formatDate(d.observationDate, "YYYYMMDD") : ''
      };

      if ( user.role.includes( 'admin' ) ) {
        row.actions = <>
          {!isDeleted && user.role.includes("admin") && (
                <img
                  src={Delete}
                  alt=""
                  onClick={() => {
                    handleDelete(d.id, ' this obsrevation');
                  }}
                />
              )}
          {isDeleted && user.role.includes("admin") && (
                <img
                  src={Restore}
                  alt=""
                  title="Restore"
                  className="undelete-btn"
                  onClick={() => {
                    handleUndelete(d.id, ' this obsrevation');
                  }}
                />
              )}
          {!isDeleted && <>
            <img
              src={Edit2}
              alt=""
              className="ms-2"
              onClick={() => {
                history.push(`/edit-safety-observation/${d.id}`)
              }}
            />
          </>}
        </>
      }

      parsedData.push(row);
    });

    if ( parsedData.length > 0 && search && search.length > 0 ) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== 'action') // Exclude 'action' property
          .map(([key, value]) => value)
          .join(' ')
          .toLowerCase();
        
        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  }

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'SafetyObservations');
  };

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Observations List", path: "/safety-observations" },
        ]}
      />

      <CustomPopup
        showClose={false}
        trigger={!pdfLoader}
        setTrigger={() => {
          
        }}
      >
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div className="main_container">
        <div>
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="listing-buttons">
              <button onClick={() => { printList() }}>Print</button>
              <button onClick={() => { downloadExcel() }}>Excel</button>
              <button onClick={() => { downloadPDF() }}>PDF</button>

              {userPermissions.includes('submitsafetyObservation') ? <>
                <button
                  className="GA2-btn"
                  onClick={() => {
                    history.push("/safety-observation/add-observation");
                  }}
                >
                  Add Safety Observation +
                </button>
              </> : <></> }
            </div>
          </div>
          <hr />
          <div id="wrapper">
            { ! loading ? <>
              <DataTable
                columns={columns}
                data={createData()}
                pagination={true}
                sortIcon={<CustomSortIcon />}
                onSort={handleSort}
                defaultSortFieldId={sortColumn}
                defaultSortAsc={sortDirection === 'asc'}
              />
            </> : <>
              <Spinner size="sm" animation="border" variant="light" />
            </>}
          </div>
        </div>
      </div>
    </div>
  );
}
