import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Trainings.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { commonService } from "../common/common.service";
import { getBase64 } from "../common/imageHelper";
import CustomPopup from "../Popup/CustomPopup";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import trainingService from "../../services/training.service";
import { onError } from "../../libs/errorLib";
import deparmentService from "../../services/deparment.service";

const AddTraining = ({ title }) => {
  const { id } = useParams();
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [allDepsVisible, setAllDepsVisible] = useState(false);
  const [sitesData, setSitesData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [questionPopup, setQuestionPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    question: "",
    options: [
      "",
      "",
      "",
    ],
    correctAnswer: "",
  });

  const fieldValues = {
    siteId: null,
    departmentId: null,
    companyId: companyId,
    addedBy: companyId,
    contractorId: null,

    task: null,  
    assessedBy: null,  
    trainingDate: commonService.formatDate( new Date(), "YYYY-MM-DD" ),
    numberOfDays: null,
    trainingType: null,
    files: [],
    questions: [],
    trainingName: null,
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, trainingDate: "" });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let chosenCompanyId = 0;
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
            chosenCompanyId = selectedCompany;
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
            chosenCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          }
          
          setFieldData({
            ...fieldData,
            companyId: chosenCompanyId,
            addedBy: chosenCompanyId,
            contractorId: user.role.includes("admin")
              ? fieldData.contractorId
              : res.data[0].contractorId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchRiskAssessmentDetails = async () => {
    setLoading(true);
    try {
      const response = await trainingService.getRiskAssessment(id);
      if (response) {
        setFieldData({ ...response[0], trainingDate: commonService.formatDate( response[0].trainingDate, "YYYY-MM-DD" ) });
        getSiteUsers(response[0].siteId);
      }
    } catch (error) {
      onError(error || "Unable to process request");
    } finally {
      setLoading(false);
    }
  };

  const getDepartments = async () => {
    try {
      const res = await deparmentService.getAllDepartments({
        companyId: companyId,
        status: "",
      });
      if (res) {
        setDepartments(
          res.filter(
            (department, index, arr) =>
              res.findIndex((item) => item.id == department.id) == index
          )
        );
      }
    } catch (error) {
      // console.log("error", error);
      // onError(error || "Unable to process request");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchRiskAssessmentDetails();
    }
    getCourses();
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  const getSites = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/` + ( user.role.includes("superadmin") ? selectedCompany : user.id ), {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getCourses = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/courses/list`,
      {
        companyId: companyId,
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setCoursesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      let siteUsersResponse = '';
      if ( siteId == '--all--' ) {
        siteUsersResponse = await axiosInstance.post(
          `/site-users-for-sites/`, {
            sites: sitesData.map((site) => site.id).join(',')
          }
        );
      } else {
        siteUsersResponse = await axiosInstance.post(
          `/site-users/${siteId}`
        );
      }
      // ensure unique users
      const siteUsersResponseData = siteUsersResponse.data.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      setLoading(false);
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {

      if (name == "siteId") {
        getSiteUsers(value);
  
        if ( value == '--all--' ) {
          // set department to 'All Departments'
          setFieldData( {
            ...fieldData,
            siteId: '--all--',
            departmentId: '--all--'
          } )

          setAllDepsVisible(true);
        } else {
          setAllDepsVisible(false);
          setFieldData({
            ...fieldData,
            [name]: value,
          });
        }
      } else {
        setFieldData({
          ...fieldData,
          [name]: value,
        });
      }

    }
  };

  const isValidData = (value, name) => {
    let message = "";
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;

      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;

      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;

      case "assessedBy":
        if (!value) {
          message = stateObj[name] = "Assessed By is required.";
        }
        break;

      case "trainingDate":
        if (!value) {
          message = stateObj[name] = "Date is required.";
        }
        break;

      case "trainingType":
        if (!value) {
          message = stateObj[name] = "Training Type is required.";
        }

      case "trainees":
        if (!value) {
          message = stateObj[name] = "Trainees are required.";
        }
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    let errorShow = {
      companyId: fieldData.companyId,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      addedBy: fieldData.addedBy,
      
      trainingDate: fieldData.trainingDate,
      trainees: fieldData.trainees,
      trainingType: fieldData.trainingType
    };

    if ( fieldData.trainingType == 2 ) {
      errorShow = {
        ...errorShow,
        course: fieldData.course,
      }
    } else {
      errorShow = {
        ...errorShow,
        trainingName: fieldData.trainingName,
        passRequired: fieldData.passRequired,
      }
    }

    let trigger = true;
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message);
        trigger = false;
      }
    });

    if ( trigger ) {
      const form = new FormData();

      const mailUpdates = siteUsers
        .filter((v) => v.type == "admin")
        .map((v) => ({
          email: v.email,
          userid: v.userid,
          fullName: v.fullName,
          emailPref: v.emailPreference
            ? v.emailPreference.includes("trainings")
            : v.permits.includes("trainingsreceiveEmails"),
          isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
        }))
        .filter((v) => v.emailPref || v.isAdminWithNoPref);

      form.append(
        "mailUpdates",
        mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
      );
      form.append(
        "notiticationUpdates",
        siteUsers
          .filter((v) => v.type == "admin")
          .filter((v) => v.userid)
          .map((v) => v.userid)
          .join(",")
      );

      form.append(
        "departmentId",
        fieldData.departmentId == null ? null : (fieldData.departmentId == '--all--' ? -1 : fieldData.departmentId)
      );
      
      form.append("siteId", fieldData == null ? null : (fieldData.siteId == '--all--' ? -1 : fieldData.siteId));
      
      form.append("companyId", companyId);
      form.append("addedBy", user.id);
      form.append("contractorId", fieldData.contractorId);
      
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });

      form.append("trainingDate", fieldData.trainingDate);
      form.append("trainees", fieldData.trainees.join(','));
      form.append("trainingType", fieldData.trainingType);
      
      if ( fieldData.trainingType == 2 ) {
        form.append("trainingName", fieldData.trainingName);
        form.append("passRate", fieldData.passRequired);
        form.append("questions", JSON.stringify(fieldData.questions));
      } else {
        form.append("courseId", fieldData.courseId);
      }

      setLoading(true);

      let response;
      try {
        if (id) {
          response = await trainingService.updateTraining(id, form);
        } else {
          response = await trainingService.addTraining(form);
        }

        if (response) {
          toast.success(response.message);
          id
            ? history.push("/training/" + id)
            : history.push("/training/" + response.id);
          setFieldData(fieldData);
        }
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }));

  const departmentOptions = allDepartment
    .filter((v) => v.siteId == fieldData.siteId)
    .map((d) => ({
      label: d.name,
      value: d.id,
      name: "departmentId",
    }));

    const passRequiredOptions = [
      { value: '80%', label: '80%', name: 'passRequired', },
      { value: '90%', label: '90%', name: 'passRequired', },
      { value: '100%', label: '100%', name: 'passRequired', },
    ];

  const addQuestionHandler = () => {
    fieldData.questions.push(popupData);
    setQuestionPopup(false);
    setPopupData({
      question: "",
      options: [
        "",
        "",
        "",
      ],
      correctAnswer: "",
    });
  };

  return (
    <div className="middle_page">

      <CustomPopup
        trigger={questionPopup}
        setTrigger={() => {
          setQuestionPopup(false);
          setPopupData({
            question: "",
            options: [
              "",
              "",
              "",
            ],
            correctAnswer: "",
          });
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              
              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Question <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <input
                      value={popupData.question}
                      type="text"
                      onChange={(e) => {
                        setPopupData({
                          ...popupData,
                          question: e.target.value,
                        });
                      }}
                      name="Question"
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Answers <span className="text-danger">*</span>
                  </div>
                </label>

                <div className="answers-choices-list">
                  {popupData?.options?.map((option, index) => (
                    <div className="answers-choice-item">
                      <label className="answers-choice-correct">
                        <input
                          type="radio"
                          name="correct_answer"
                          value={index}
                          onChange={(e) => {
                            setPopupData({
                              ...popupData,
                              correctAnswer: e.target.value,
                            });
                          }}
                        />
                        <span></span>
                      </label>
                      <div className="answers-choice-text">
                        <input
                          type="text"
                          value={popupData.options[index]}
                          placeholder={`Option ${index + 1}`}
                          onChange={(e) => {
                            let options = popupData.options;
                            options[index] = e.target.value;
                            setPopupData({
                              ...popupData,
                              options: options,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sign_save_reset_btn">
                <button
                  className="btn btn-success"
                  disabled={
                    !popupData?.question ||
                    !popupData?.options ||
                    !popupData?.correctAnswer
                  }
                  onClick={addQuestionHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomPopup>

      <Header title={title} steps={[{ name: "Home", path: "/" }]} />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">
            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={[{ value: "--all--", label: "All Sites", name: "siteId" }, ...siteOptions]}
                      {...(id
                        ? {
                            value: siteOptions.filter(
                              (v) => v.value == fieldData.siteId
                            ),
                            isDisabled: true,
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Company
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Contractor"
                      value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      })) : null}
                      options={contractorData.map((d) => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      }))}
                    />
                  </div>
                </div>
                {error.contractorId && (
                  <span className="err">{error.contractorId}</span>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    {allDepsVisible ? <>
                      <span className="all-deps-name">All Departments</span>
                    </> : <>
                      <Select
                        onChange={handleChange}
                        placeholder="Select Department"
                        options={departmentOptions}
                        {...(id
                          ? {
                              value: departmentOptions.filter(
                                (v) => v.value == fieldData.departmentId
                              ),
                              isDisabled: true,
                            }
                          : {})}
                      />
                    </>}
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>
            </div>

            <div className="col-md-6" style={{ display: "none" }}>
              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Date <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.trainingDate}
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleChange}
                      name="trainingDate"
                    />
                  </div>
                </div>
                {error.trainingDate && (
                  <span className="err">{error.trainingDate}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <FormControl>
                  <FormLabel class="inspected_label">
                    Training Type <span className="text-danger">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="trainingType"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.trainingType == 1} value={1} control={<Radio />} label="Required" />
                    <FormControlLabel checked={fieldData.trainingType == 2} value={2} control={<Radio />} label="Induction" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            {fieldData.trainingType == 1 && <>
              <div className="col-md-12">
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Course <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select Course"
                        options={coursesData.map((d) => ({
                          label: d.course_name,
                          value: d.id,
                          name: "courseId",
                        }))}
                        {...(id
                          ? {
                              value: coursesData.filter(
                                (v) => v.value == fieldData.courseId
                              ),
                              isDisabled: true,
                            }
                          : {})}
                      />
                    </div>
                  </div>
                  {error.courseId && (
                    <span className="err">{error.courseId}</span>
                  )}
                </div>
              </div>
            </>}

            {fieldData.trainingType == 2 && <>
              <div className="col-md-12">
                <div className="w-100 p-2 location_ladder">
                  <label for="">
                    <div className="require-label">
                      Training Name <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="trainingName"
                        {...(id
                          ? {
                              value: fieldData.trainingName,
                            }
                          : {})}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.trainingName && <span className="err">{error.trainingName}</span>}
                </div>
              </div>
            </>}

            <div className="col-md-12">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Trainee(s) <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(selectedOptions) => {
                        
                        if (selectedOptions) {
                          const isSelectAllSelected = selectedOptions.some(
                            (option) => option.value === 'all'
                          );
                      
                          let value = [];
                      
                          if (isSelectAllSelected) {
                            // If "Select All" is selected, select all user ids
                            value = siteUsers.map((v) => v.userid);
                          } else {
                            value = selectedOptions.map((v) => v.value);
                          }
                      
                          setFieldData({
                            ...fieldData,
                            trainees: value
                          });
                        }
                      }}
                      isMulti
                      placeholder="Select User"
                      options={[{
                        label: "All Users",
                        value: "all",
                        name: "trainees",
                      }, ...siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName ? v.contractorName : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "trainees",
                      }))]}
                      value={siteUsers
                        .filter((v) => fieldData?.trainees?.includes(`${v.userid}`))
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "trainees",
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>
            
            {fieldData.trainingType == 2 && <div className="col-md-12">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Pass Rate Required <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Please select..."
                      options={passRequiredOptions}
                      {...(id ? {
                        value: passRequiredOptions.filter(v => v.value == fieldData.passRequired),
                      } : {})}
                    />
                  </div>
                </div>
                {error.trigger && <span className="err">{error.trigger}</span>}
              </div>
            </div>}

          </div>
        </div>

        {fieldData.trainingType == 2 && <>
          <div className="addladder_fifth_container">
            <div className="general_details">Questions</div>

            <div className="training-questions-wrapper p-2">

              {fieldData.questions ? <>
                {fieldData.questions.map((question, index) => (
                  <div className="training-question" key={index}>
                    <div className="training-questions-header">
                      <div className="training-questions-header-label">
                        {index + 1 }. {question.question}
                      </div>
                    </div>
                    <div className="training-questions-answers">
                      {question.options.map((option, qIndex) => (
                        <div className={"training-questions-answer " + (question.correctAnswer == qIndex ? "correct-answer" : "")} key={qIndex}>
                          <div className={"training-questions-answer-label"}>
                            {option}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </> : <>
                <div className="no-questions-found">
                  <h4>No Question Added.</h4>
                </div>
              </>}

              <div className="add-training-question">
                <button
                  type="button"
                  onClick={() => {
                    setQuestionPopup(true);
                  }}
                  className="btn btn-primary btn-sm"
                >
                  + Add Question
                </button>
              </div>
            </div>
          </div>
        </>}

        <div className="addladder_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              {imgPreviewUrl.search(':video') == -1 ? (
                <img
                  className="obj_fit_img obj-fit-cover rounded"
                  src={imgPreviewUrl}
                  alt=""
                  height="150"
                  width="150"
                />
              ) : (
                <video
                  className="obj_fit_img obj-fit-cover rounded"
                  src={imgPreviewUrl}
                  alt=""
                  height="150"
                  width="150"
                  controls
                />
              )}
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button
            className="cancel_btn"
            onClick={() => history.push("/trainings/")}
          >
            cancel
          </button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddTraining;
