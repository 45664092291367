import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./SafetyObservations.scss";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { commonService } from "../common/common.service";

const AddSafetyObservation = ({ title }) => {
  const { id } = useParams();
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [hourlyRate, setHourlyRate] = useState(0);
  const [sitesData, setSitesData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    assignTo: null,

    observationDate: "",
    observationType: null,
    observationCategory: null,
    observationDetails: null,

    isActionRequired: null,
    actionRequired: null,

    observationStatus: null,
    completionDate: null,

    companyId: companyId,
    addedBy: companyId,
    files: "",

    numberOfMen: null,
    numberOfHours: null,

    contractors: null
  };

  const actionValues = {};
  actionValues[1] = {
    assignTo: "",
    action: "",
    date: new Date()
      .toLocaleString("en-GB")
      .substring(0, 10)
      .split("/")
      .reverse()
      .join("-"),
    status: "",
    files: "",
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({
    ...fieldValues,
    contractorId: null,
    startDate: null,
  });

  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const statusOptions = [
    { value: "Open", label: "Open", name: "observationStatus" },
    { value: "In Progress", label: "In Progress", name: "observationStatus" },
    { value: "Closed", label: "Closed", name: "observationStatus" },
    //{ value: "Counter Charge", label: "Counter Charge", name: "observationStatus" },
  ];

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          });

          fetchSafetyObservationCategories(userCompanyId);
          fetchSafetyObservationTypes(userCompanyId);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };
  
  const getHourlyRate = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/get-hourly-rate`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setHourlyRate(res.data.hourlyRate);
      })
      .catch((err) => {
        
      });
  };

  const fetchSafetyObservationCategories = (companyId) => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/categories/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        const fetchedCategoryOptions = res.data.categories.map((category) => ({
          name: "observationCategory",
          value: category,
          label: category
        }));

        setCategoryOptions((prevOptions) => {
          // Filter out categories that are already present
          const newOptions = fetchedCategoryOptions.filter(
              (option) => !prevOptions.some((prevOption) => prevOption.value === option.value)
          );

          return [...prevOptions, ...newOptions];
        });

      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const fetchSafetyObservationTypes = (companyId) => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/types/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        const fetchedTypeOptions = res.data.types.map((type) => ({
          name: "observationType",
          value: type,
          label: type
        }));

        setTypeOptions((prevOptions) => {
          // Filter out categories that are already present
          const newOptions = fetchedTypeOptions.filter(
              (option) => !prevOptions.some((prevOption) => prevOption.value === option.value)
          );

          return [...prevOptions, ...newOptions];
        });

      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const fetchSafetyObservationDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/details`,
        {
          safetyObservationId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({
          ...res.data[0],
          existingFiles: res.data[0].files,
          contractors: res.data[0].counterChargedContractorIds ? res.data[0].counterChargedContractorIds.split(',').map( id => parseInt(id) ) : null
        });

        const detailCategory = res.data[0].observationCategory;
        const detailType = res.data[0].observationType;

        if (detailCategory && !categoryOptions.some((option) => option.value === detailCategory)) {
          setCategoryOptions((prevOptions) => [
            ...prevOptions,
            {
              name: "observationCategory",
              value: detailCategory,
              label: detailCategory,
            },
          ]);
        }

        if (detailType && !typeOptions.some((option) => option.value === detailType)) {
          setTypeOptions((prevOptions) => [
            ...prevOptions,
            {
              name: "observationType",
              value: detailType,
              label: detailType,
            },
          ]);
        }

        setLoading(false);
        getSiteUsers(res.data[0].siteId);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchSafetyObservationDetails();
    }
    getSites();
    getDepartments();
    getContractor();
    //getHourlyRate();
  }, [companyId]);

  useEffect(() => {
    if ( selectedCompany ) {
      setUserID(selectedCompany);
    }
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value);
      }
    }
  };

  const isValidData = (value, name) => {
    let message = "";
    const stateObj = { ...error, [name]: "" };

    const errors = {
      companyId: "Company Id is required.",
      addedBy: "Added by is required.",
      siteId: "Site Id is required.",
      departmentId: "Department Id is required.",
      assignTo: "Assigned to is required.",
      observationDate: "Observation Date is required.",
      isActionRequired: "Is Action Required is required.",
      actionRequired: "Action Required is required.",
      observationType: "Observation Type is required.",
      observationCategory: "Observation Category is required.",
      observationDetails: "Observation Details is required.",
      contractorId: "Contractor is required.",
      observationStatus: "Observation Status is required.",
      completionDate: "Completion Date is required.",
      numberOfHours: "Number of Hours is a required field.",
      numberOfMen: "Number of Men is a required field.",
      contractors: "Assign to is a required field.",
    };
    

    if ( typeof errors[ name ] !== 'undefined' ) {
      if ( ! value ) {
        message = stateObj[ name ] = errors[ name ];
        return message;
      }

      if ( name == 'contractors' && value.length == 0 ) {
        message = stateObj[ name ] = errors[ name ];
        return message;
      }
    }

    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      handleSubmitAfterSave();
    } catch (error) {
      console.log(error);
      // toast.error("Failed to save signature");
    }
  };

  const handleSubmitAfterSave = () => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,

      observationDate: fieldData.observationDate,
      observationType: fieldData.observationType,
      observationCategory: fieldData.observationCategory,
      observationDetails: fieldData.observationDetails,
      accidentDetails: fieldData.accidentDetails,

      isActionRequired: fieldData.isActionRequired,
    };

    if ( false && fieldData.observationStatus == 'Counter Charge' ) {
      errorShow.numberOfMen = fieldData.numberOfMen;
      errorShow.numberOfHours = fieldData.numberOfHours;
      errorShow.contractors = fieldData?.contractors;
    } else {
      errorShow.assignTo = fieldData.assignTo;
    }

    if ( fieldData.isActionRequired == 1 ) {
      errorShow.completionDate = fieldData.completionDate;
      errorShow.observationStatus = fieldData.observationStatus;
      errorShow.actionRequired = fieldData.actionRequired;
    }

    let trigger = true;
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message);
        trigger = false;
      }
    });

    if (
      fieldData.isActionRequired !== null &&
      fieldData.observationDate !== null &&
      fieldData.observationType !== null &&
      fieldData.observationCategory !== null &&
      fieldData.observationDetails !== null &&
      fieldData.accidentDetails !== null &&
      ( fieldData.observationStatus == 'xCounter Charge' || fieldData.assignTo !== null ) &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      ( fieldData.observationStatus !== 'Counter Charge' || ( fieldData.contractors.length > 0 && fieldData.numberOfMen != null && fieldData.numberOfHours != null ) )

      && ( fieldData.isActionRequired == 0 || ( fieldData.completionDate !== null && fieldData.observationStatus !== null && fieldData.actionRequired !== null ) )
    ) {
      const form = new FormData();

      const mailUpdates = siteUsers
        .filter((v) => v.type == "admin")
        .map((v) => ({
          email: v.email,
          userid: v.userid,
          fullName: v.fullName,
          emailPref: v.emailPreference
            ? v.emailPreference.includes("safetyObservation")
            : v.permits.includes("safetyObservationreceiveEmails"),
          isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
        }))
        .filter((v) => v.emailPref || v.isAdminWithNoPref);
      form.append(
        "mailUpdates",
        mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
      );
      form.append(
        "notiticationUpdates",
        siteUsers
          .filter((v) => v.type == "admin")
          .filter((v) => v.userid)
          .map((v) => v.userid)
          .join(",")
      );

      form.append(
        "departmentId",
        fieldData.departmentId == null ? null : fieldData.departmentId
      );
      form.append("siteId", fieldData == null ? null : fieldData.siteId);

      form.append("observationDate", fieldData.observationDate);
      form.append("observationType", fieldData.observationType);
      form.append("observationCategory", fieldData.observationCategory);
      form.append("observationDetails", fieldData.observationDetails);
      form.append("isActionRequired", fieldData.isActionRequired);
      form.append("actionRequired", fieldData.actionRequired);
      // form.append("actionRequired", fieldData.actionRequired);
      let assignTo = fieldData.assignTo ? fieldData.assignTo.split("--")[0] : '';

      if ( fieldData.isActionRequired == 0 ) {
        form.append("observationStatus", 'Closed'); // Not applicable
      } else {
        form.append("observationStatus", fieldData.observationStatus);

        // Append Counter Charge fields.
        if ( false && fieldData.observationStatus === 'Counter Charge' ) {
          form.append("numberOfMen", fieldData.numberOfMen);
          form.append("numberOfHours", fieldData.numberOfHours);
          form.append("contractors", fieldData?.contractors);
        }
      }

      form.append("completionDate", fieldData.completionDate);
      form.append("trade", fieldData.trade);

      form.append("assignTo", assignTo);

      siteUsers.filter((d) => {
        if (d.id == assignTo) {
          form.append("assignToName", d.fullName);
          form.append("assignToEmail", d.email);
        }
      });

      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall = id
        ? axiosInstance.put(
            `${process.env.REACT_APP_API_BASEURL}/update-details/safety-observation/${id}`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
        : axiosInstance.post(
            `${process.env.REACT_APP_API_BASEURL}/add/safety-observation`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
          history.push("/view-own/safety-observations");
          setFieldData(fieldData);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }));

  const departmentOptions = allDepartment
    .filter((v) => v.siteId == fieldData.siteId)
    .map((d) => ({
      label: d.name,
      value: d.id,
      name: "departmentId",
    }));

  return (
    <div className="middle_page">
      <Header title={title} steps={[{ name: "Home", path: "/" }]} />

      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">General Details</div>

          <div className="row">
            <div className="col-md-12">
              
              <div className="p-2">
                <FormControl>
                  <FormLabel class="inspected_label">
                    Is Action Required <span className="text-danger">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isActionRequired"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      checked={fieldData.isActionRequired == 1}
                      value={1}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      checked={fieldData.isActionRequired == 0}
                      value={0}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>
          </div>

          {fieldData.isActionRequired == 1 && (
            <div className="p-2">
              <div className="addaccess_second_container">
                <div className="general_details">Required Action Details</div>
                <div className="flex flex-wrap">
                  <div className="col-8">
                    <div className="form_inner p-2 w-100">
                      <label for="">
                        Action Required <span className="text-danger">*</span>
                      </label>
                      <div className="input_icons">
                        <div className="input_field">
                          <textarea
                            name="actionRequired"
                            maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                            rows="5"
                            className="w-100"
                            cols="100"
                            value={fieldData.actionRequired}
                            onChange={handleChange}
                            placeholder="Provide information about what action should be taken to resolve the issue."
                          ></textarea>
                        </div>
                      </div>
                      <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                      {error.actionRequired && (
                        <span className="err">{error.actionRequired}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="w-100 p-2">
                      <label for="">
                        <div className="require-label">
                          Status <span className="text-danger">*</span>
                        </div>
                      </label>
                      <div className="input_icons">
                        <div className="w-100">
                          <Select
                            onChange={handleChange}
                            placeholder="Select a status"
                            options={statusOptions}
                            {...(id
                              ? {
                                  value: statusOptions.filter(
                                    (v) => v.value == fieldData.observationStatus
                                  ),
                                  // isDisabled: true
                                }
                              : {})}
                          />
                        </div>
                      </div>
                      {error.observationStatus && (
                        <span className="err">{error.observationStatus}</span>
                      )}
                    </div>

                    <div className="w-100 p-2 location_access">
                      <label for="">
                        <div className="require-label">
                          {" "}
                          Estimated Completion Date{" "}
                          <span className="text-danger">*</span>
                        </div>
                      </label>
                      <div className="input_icons">
                        <div className="input_field">
                          <input
                            value={fieldData.completionDate}
                            min={new Date()
                              .toLocaleString("en-GB")
                              .substring(0, 10)
                              .split("/")
                              .reverse()
                              .join("-")}
                            type="date"
                            onChange={handleChange}
                            name="completionDate"
                          />
                        </div>
                      </div>
                      {error.completionDate && (
                        <span className="err">{error.completionDate}</span>
                      )}
                    </div>
                  </div>

                  {false && fieldData.observationStatus == 'Counter Charge' && <>
                    <div className="col-12">
                      <div className="flex flex-wrap">
                        <div className="col-6">
                          <div className="w-100 p-2 location_access">
                            <label for="">
                              <div className="require-label">
                                {" "}
                                Number of Men{" "}
                                <span className="text-danger">*</span>
                              </div>
                            </label>
                            <div className="input_icons">
                              <div className="input_field">
                                <input
                                  type="number"
                                  value={fieldData.numberOfMen}
                                  onChange={handleChange}
                                  name="numberOfMen"
                                />
                              </div>
                            </div>
                            {error.numberOfMen && (
                              <span className="err">{error.numberOfMen}</span>
                            )}
                          </div>
                        </div>
                        
                        <div className="col-6">
                          <div className="w-100 p-2 location_access">
                            <label for="">
                              <div className="require-label">
                                {" "}
                                Number of Hours{" "}
                                <span className="text-danger">*</span>
                              </div>
                            </label>
                            <div className="input_icons">
                              <div className="input_field">
                                <input
                                  type="number"
                                  value={fieldData.numberOfHours}
                                  onChange={handleChange}
                                  name="numberOfHours"
                                />
                              </div>
                            </div>
                            {error.numberOfHours && (
                              <span className="err">{error.numberOfHours}</span>
                            )}
                          </div>
                        </div>
                        
                        {hourlyRate ? <>
                          <div className="col-6">
                            <div className="w-100 p-2 location_access">
                              <label for="">
                                <div className="require-label">
                                  Amount Lost 
                                </div>
                              </label>
                              <div className="input_icons">
                                <div className="input_field">
                                  <input
                                    type="text"
                                    readOnly
                                    disabled
                                    value={ '€' + (fieldData.numberOfHours * fieldData.numberOfMen * hourlyRate).toFixed(2) }
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </> : <>
                          <div className="col-6 p-2"><span className="text-danger">Hourly Rate is not set. It must be set before a counter charged safety observation can be submitted.</span></div>
                        </>}

                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          )}

          <div className="row">

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id
                        ? {
                            value: siteOptions.filter(
                              (v) => v.value == fieldData.siteId
                            ),
                            isDisabled: true,
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>

              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id
                        ? {
                            value: departmentOptions.filter(
                              (v) => v.value == fieldData.departmentId
                            ),
                            isDisabled: true,
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>

              {false && fieldData.isActionRequired == 1 && fieldData.observationStatus == 'Counter Charge' ? <>
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Assign To <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={(selectedOptions) => {

                          // console.log(selectedOptions);
                        
                          if (selectedOptions) {
                            let value = [];
                        
                            value = selectedOptions.map((v) => v.value);

                            setFieldData({
                              ...fieldData,
                              contractors: value
                            });
                          }
                        }}
                        placeholder="Select Contractor"
                        isMulti
                        value={contractorData
                          .filter((v) => fieldData?.contractors?.includes(parseInt(v.id)))
                          .map((v) => ({
                            label: v.name,
                            value: v.id,
                            name: "contractors",
                          }))}

                        options={contractorData.map((d) => ({
                          label: d.name,
                          value: d.id,
                          name: "contractorId",
                        }))}
                      />
                    </div>
                  </div>
                  {error.contractorId && (
                    <span className="err">{error.contractorId}</span>
                  )}
                </div>
              </> : <>
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Assign To <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select User"
                        options={siteUsers.map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignTo",
                        }))}
                        value={siteUsers
                          .filter((v) => `${v.userid}` == fieldData?.assignTo)
                          .map((v) => ({
                            label:
                              v.fullName +
                              " - " +
                              (v.contractorName ? v.contractorName : v.companyName) +
                              " - " +
                              (v.roleName ? v.roleName : v.role),
                            value: `${v.userid}`,
                            name: "assignTo",
                          }))}
                      />
                    </div>
                  </div>
                </div>
              </>}

            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Date <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.observationDate}
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleChange}
                      name="observationDate"
                    />
                  </div>
                </div>
                {error.observationDate && (
                  <span className="err">{error.observationDate}</span>
                )}
              </div>

              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Category <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select a category"
                      options={categoryOptions}
                      {...(id
                        ? {
                            value: categoryOptions.filter(
                              (v) => v.value == fieldData.observationCategory
                            ),
                            // isDisabled: true
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.observationCategory && (
                  <span className="err">{error.observationCategory}</span>
                )}
              </div>

              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Type <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select a type"
                      options={typeOptions}
                      {...(id
                        ? {
                            value: typeOptions.filter(
                              (v) => v.value == fieldData.observationType
                            ),
                            // isDisabled: true
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.observationType && (
                  <span className="err">{error.observationType}</span>
                )}
              </div>

              {fieldData.isActionRequired == 1 && fieldData.observationCategory == 'Housekeeping' && <>
              <div className="p-2">
                <div className="popup-warning-notice popup-warning-with-border">
                  <p>Please Note:<br />If the area highlighted in the Observation is not cleaned to an acceptable standard and closed out within the estimated time and date given, you will be contra charged in your next schedule of payments!</p>
                </div>
              </div>
              </>}

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form_inner p-2 w-100">
                <label for="">
                  Details <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="observationDetails"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      className="w-100"
                      cols="100"
                      value={fieldData.observationDetails}
                      onChange={handleChange}
                      placeholder="Enter a description of the observation here"
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                {error.observationDetails && (
                  <span className="err">{error.observationDetails}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="addladder_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length &&
              files.map((imgPreviewUrl) => (
                <div id="profile_pic_1_preview" className="image-fixed m-2">
                  <img
                    className="obj_fit_img obj-fit-cover rounded"
                    src={imgPreviewUrl}
                    alt=""
                    height="150"
                    width="150"
                  />
                </div>
              ))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>

        <div className="mt-3">
          {!loading ? (
            <>
              { false && fieldData.isActionRequired == 1 && fieldData.observationStatus == 'Counter Charge' && ! hourlyRate ? <>
                <span className="text-danger">Counter Charged Safety Observations can only be submitted when hourly rate is present in Company settings.<br /><br /></span>
              </> : <>
                <button
                  className="btn btn-success me-2"
                  onClick={() => handleSubmit()}
                >
                  {id ? "Update" : "Submit"}
                </button>
              </>}
            </>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddSafetyObservation;
