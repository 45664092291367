import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { commonService } from "../common/common.service";
import fileIcon from "../../images/openfolderwhite.svg";
import "./PPEIssueReports.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";

const AddPPEIssueReport = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    id: null,
    siteId: null,
    departmentId: null,
    companyId: companyId,
    contractorId: null,
    addedBy: companyId,
    item: null,
    receivedBy: null,
    comments: null,
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [error, setError] = useState({ ...fieldValues });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [notificationEmail, setNotificationEmail] = useState('');
  
  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(userCompanyId);
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
            contractorId: ( user.role.includes("admin") ? fieldData.contractorId : res.data[0].contractorId )
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchPPEIssueReport = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ppe-issue-reports/details`,
        {
          ppeIssueReportId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0] });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchPPEIssueReport()
    }
    getDepartments();
    getSites();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        let options = res.data.filter(
          (site, index, arr) =>
            res.data.findIndex((item) => item.id == site.id) == index
        );
        
        // insert item at first position
        options = [{
          id: -1,
          name: user.companyName,
        }, ...options];

        if ( ! id ) {
          setFieldData({
            ...fieldData,
            otherCompany: user.companyName
          });
        }

        setContractorData(
          options
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
      ...fieldData,
      [name]: value,
    });

    if ( name == 'contractorId' ) {
      // get email address from first match in filter
      setNotificationEmail( contractorData.find(v => v.id == value).email );
    }
    if (name == "siteId") {
      getSiteUsers(value)
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;
      
      case "item":
        if (!value) {
          message = stateObj[name] = "Item is required.";
        }
        break;
      
      case "comments":
        if (!value) {
          message = stateObj[name] = "Issue raised is required.";
        }
        break;
      
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Signature is required.";
        }
        break;
      
      case "receivedBy":
        if (!value) {
          message = stateObj[name] = "Received By is required.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const signatureVerifyRef = useRef();
  
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const handleSubmit = async () => {
    if ( id ) {
      handleSubmitAfterSave(null)
    } else {
      try {
        const [verifySignature] = await Promise.all( [ handleSaveVerifySign() ] )
        handleSubmitAfterSave(verifySignature)
      } catch (error) {
        toast.error("Failed to save signature")
      }
    }
  }

  const handleSubmitAfterSave = ( verifySignature ) => {
    let errorShow = {
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      
      receivedBy: fieldData.receivedBy,
      item: fieldData.item,
      
      contractorId: fieldData.contractorId,
    };

    if ( ! id ) {
      errorShow.verifySignature = verifySignature;
    }

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.contractorId != null &&
      fieldData.siteId != null &&
      
      fieldData.item != null &&
      fieldData.receivedBy != null &&

      ( id || verifySignature !== null) &&
      
      fieldData.contractorId != null
      
    ) {
      
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("ppeIssueReports") : v.permits.includes("ppeIssueReportsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      
      // form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("companyId", companyId);
      form.append("addedByInCompany", user.id);
      form.append("contractorId", fieldData.contractorId);
      form.append("emailToNotify", notificationEmail);

      if ( ! id ) {
        form.append("verifySignature", verifySignature);
      }

      if ( fieldData.contractorId == -1 ) {
        form.append("otherCompany", fieldData.otherCompany);
      }
      
      form.append("receivedBy", fieldData.receivedBy);
      form.append("item", fieldData.item);
      form.append("comments", fieldData.comments);
      
      
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update/ppe-issue-report/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/ppe-issue-report`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        
        id ? history.push('/ppe-issue-reports/' + id) : history.push('/view-own/ppe-issue-reports');

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">
            
            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id ? {
                        value: siteOptions.filter(v => v.value == fieldData.siteId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id ? {
                        value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Item <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="item"
                      {...(id ? {
                        value: fieldData.item,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.item && (
                  <span className="err">{error.item}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Company <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Contractor"
                      value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      })) : null}
                      options={contractorData.map((d) => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      }))}
                    />
                  </div>
                </div>
                {error.contractorId && (
                  <span className="err">{error.contractorId}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Received By <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="receivedBy"
                      {...(id ? {
                        value: fieldData.receivedBy,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.receivedBy && (
                  <span className="err">{error.receivedBy}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_inner p-2 w-100">
                <label for="">
                Additional Comments
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="comments"
                      rows="5"
                      className="w-100"
                      cols="100"
                      value={fieldData.comments}
                      onChange={handleChange}
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {!id ? <>
              <div className="col-md-4">
                <div className="p-2">
                  <strong>Submitter Signature</strong>
                  <div className="sign_image submitter_signature">
                    <SignatureCanvas
                      ref={signatureVerifyRef}
                      canvasProps={{ width: 263, height: 270 }}
                    />
                    <span
                      className="cancel-custom cursor-pointer"
                      onClick={handleClearVerifySign}
                    >
                      <i
                        className="clear_btn  text-danger fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    </span>


                    
                  </div>
                  {error.verifySignature && (
                    <span className="err">{error.verifySignature}</span>
                  )}
                </div>
              </div>
            </> : '' }

          </div>

        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn" onClick={() => history.push("/ppe-issue-reports/")}>cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddPPEIssueReport;
