import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./GA2.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { FormHelperText } from "@mui/material";
import { commonService } from "../common/common.service";

const AddGA2 = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    descriptionOfWorks: null,
    inspectedBy: null,
    ratedCapacity: null,
    wireRopeChain: null,
    limitSwitch: null,
    ropesPosition: null,
    structure: null,
    hooksAndLoad: null,
    hydraulic: null,
    electical: null,
    fuelLines: null,
    brakesClutches: null,
    operatorsCab: null,
    operatorsControls: null,
    anemometer: null,
    otherMatters: null,
    flashingBeakon: null,
    reversingTravel: null,
    safetyPins: null,
    seatBelts: null,
    stateGoodOrder: null,
    date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    verifySignature: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
    resubmitComment: null
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, date: null });
  const [contractorData, setContractorData] = useState([]);
  const [serialNumberData, setSerialNumberData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchGA2Details = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ga2/permit/details`,
        {
          ga2Id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();

  }, []);
  
  useEffect(() => {
    if (id) {
      fetchGA2Details()
    } else {
      fetchCustomQuestions(); // Fetch these questions only if it is not an edit.
    }
    getSites();
    getDepartments();
    getContractor();
    getSerialNumbers();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };
  
  const getSerialNumbers = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ga1-serial-numbers`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setSerialNumberData(
          res.data
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const fetchCustomQuestions = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ga2/company-questions`,
        {
          companyId: companyId,
          userId: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then( (res) => {
        setCustomQuestions(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      } )
      .catch( (err) => {
      } );
  }

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "inspectedBy":
        if (!value) {
          message = stateObj[name] = "Inspected By is required.";
        }
        break;
      case "serialNumber":
        if (!value) {
          message = stateObj[name] = "Serial Number is required.";
        }
        break;
      case "ratedCapacity":
        if (!value) {
          message = stateObj[name] = "Rated capacity indicator / limiter is required.";
        }
        break;
      case "wireRopeChain":
        if (!value) {
          message = stateObj[name] = "Wire rope and chain systems is required.";
        }
        break;
      case "limitSwitch":
        if (!value) {
          message = stateObj[name] = "Limit switches (e.g. Hoist, Derrik limit.) is required.";
        }
        break;
      case "ropesPosition":
        if (!value) {
          message = stateObj[name] = "Ropes positioned on their sheaves is required.";
        }
        break;
      case "structure":
        if (!value) {
          message = stateObj[name] = "Structure (major damage) is required.";
        }
        break;
      case "hooksAndLoad":
        if (!value) {
          message = stateObj[name] = "Hooks or other load lifting attachments is required.";
        }
        break;
      case "hydraulic":
        if (!value) {
          message = stateObj[name] = "Hydraulic Systems is required.";
        }
        break;
      case "electical":
        if (!value) {
          message = stateObj[name] = "Electrical systems is required.";
        }
        break;
      case "fuelLines":
        if (!value) {
          message = stateObj[name] = "Fuel Lines is required.";
        }
        break;
      case "brakesClutches":
        if (!value) {
          message = stateObj[name] = "Brakes and clutches is required.";
        }
        break;
      case "operatorsCab":
        if (!value) {
          message = stateObj[name] = "Operators cab is required.";
        }
        break;
      case "operatorsControls":
        if (!value) {
          message = stateObj[name] = "Operators controls is required.";
        }
        break;
      case "anemometer":
        if (!value) {
          message = stateObj[name] = "Anenometer, where provided is required.";
        }
        break;
      case "otherMatters":
        if (!value) {
          message = stateObj[name] = "Other matters (Manufacturer / user) is required.";
        }
        break;
      case "flashingBeakon":
        if (!value) {
          message = stateObj[name] = "Flashing Beacon working is required.";
        }
        break;
      case "reversingTravel":
        if (!value) {
          message = stateObj[name] = "Reversing/Travel alarm working is required.";
        }
        break;
      case "safetyPins":
        if (!value) {
          message = stateObj[name] = "Safety Pins/QuickHitch all assosiated clips present and in good order is required.";
        }
        break;
      case "seatBelts":
        if (!value) {
          message = stateObj[name] = "Seat Belts is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Description of Works is required.";
        }
        break;
      case "stateGoodOrder":
        if (!value) {
          message = stateObj[name] = "State whether in good order field is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "date":
        if (!value) {
          message = stateObj[name] = "date is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "files":
        if (!value) {
          message = stateObj[name] = "You must select at least 1 file to upload.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign()])
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      inspectedBy: fieldData.inspectedBy,
      
      descriptionOfWorks: fieldData.descriptionOfWorks,
      stateGoodOrder: fieldData.stateGoodOrder,
      contractorId: user.contractorId||user.role=="admin"?fieldData.contractorId:true,
      date: fieldData.date,
      verifySignature: verifySignature,
      files: (fieldData.drawingSpecsCalc == 1) ? files.length !== 0 : true,
    };

    if ( companyId != 161 ) {
      errorShow = {
        ...errorShow,
        serialNumber: fieldData.serialNumber
      }
    }

    if ( customQuestions.length == 0 ) {
      // If we don't have custom questions then force the default questions.
      errorShow = {
        ...errorShow,
        ratedCapacity: fieldData.ratedCapacity,
        wireRopeChain: fieldData.wireRopeChain,
        limitSwitch: fieldData.limitSwitch,
        ropesPosition: fieldData.ropesPosition,
        structure: fieldData.structure,
        hooksAndLoad: fieldData.hooksAndLoad,
        hydraulic: fieldData.hydraulic,
        electical: fieldData.electical,
        fuelLines: fieldData.fuelLines,
        brakesClutches: fieldData.brakesClutches,
        operatorsCab: fieldData.operatorsCab,
        operatorsControls: fieldData.operatorsControls,
        anemometer: fieldData.anemometer,
        otherMatters: fieldData.otherMatters,
        flashingBeakon: fieldData.flashingBeakon,
        reversingTravel: fieldData.reversingTravel,
        safetyPins: fieldData.safetyPins,
        seatBelts: fieldData.seatBelts,
      }
    }

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.inspectedBy !== null &&
      (companyId == 161 || fieldData.serialNumber !== null) &&

      ( customQuestions.length > 0 || (
        fieldData.ratedCapacity !== null &&
        fieldData.wireRopeChain !== null &&
        fieldData.limitSwitch !== null &&
        fieldData.ropesPosition !== null &&
        fieldData.structure !== null &&
        fieldData.hooksAndLoad !== null &&
        fieldData.hydraulic !== null &&
        fieldData.electical !== null &&
        fieldData.fuelLines !== null &&
        fieldData.brakesClutches !== null &&
        fieldData.operatorsCab !== null &&
        fieldData.operatorsControls !== null &&
        fieldData.anemometer !== null &&
        fieldData.otherMatters !== null &&
        fieldData.flashingBeakon !== null &&
        fieldData.reversingTravel !== null &&
        fieldData.safetyPins !== null &&
        fieldData.seatBelts !== null
      ) ) && 

      fieldData.descriptionOfWorks !== null &&
      fieldData.stateGoodOrder !== null &&
      (user.contractorId||user.role=="admin"?fieldData.contractorId !== null:true) &&
      fieldData.date !== null &&
      verifySignature !== null
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("ga2plantonly") : v.permits.includes("ga2plantonlyreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("inspectedBy", fieldData.inspectedBy);
      
      form.append("serialNumber", ( companyId == 161 ? null : fieldData.serialNumber ) );
      
      form.append("ratedCapacity", customQuestions.length == 0 ? fieldData.ratedCapacity : 2 );
      form.append("wireRopeChain", customQuestions.length == 0 ? fieldData.wireRopeChain : 2 );
      form.append("limitSwitch", customQuestions.length == 0 ? fieldData.limitSwitch : 2 );
      form.append("ropesPosition", customQuestions.length == 0 ? fieldData.ropesPosition : 2 );
      form.append("structure", customQuestions.length == 0 ? fieldData.structure : 2 );
      form.append("hooksAndLoad", customQuestions.length == 0 ? fieldData.hooksAndLoad : 2 );
      form.append("hydraulic", customQuestions.length == 0 ? fieldData.hydraulic : 2 );
      form.append("electical", customQuestions.length == 0 ? fieldData.electical : 2 );
      form.append("fuelLines", customQuestions.length == 0 ? fieldData.fuelLines : 2 );
      form.append("brakesClutches", customQuestions.length == 0 ? fieldData.brakesClutches : 2 );
      form.append("operatorsCab", customQuestions.length == 0 ? fieldData.operatorsCab : 2 );
      form.append("operatorsControls", customQuestions.length == 0 ? fieldData.operatorsControls : 2 );
      form.append("anemometer", customQuestions.length == 0 ? fieldData.anemometer : 2 );
      form.append("otherMatters", customQuestions.length == 0 ? fieldData.otherMatters : 2 );
      form.append("flashingBeakon", customQuestions.length == 0 ? fieldData.flashingBeakon : 2 );
      form.append("reversingTravel", customQuestions.length == 0 ? fieldData.reversingTravel : 2 );
      form.append("safetyPins", customQuestions.length == 0 ? fieldData.safetyPins : 2 );
      form.append("seatBelts", customQuestions.length == 0 ? fieldData.seatBelts : 2 );

      if ( customQuestions.length > 0 ) {
        let customQuestionsAnswers = [];
        // add all custom questions from fieldData
        customQuestions.forEach((question) => {
          customQuestionsAnswers.push( {q: question.question, a: ( fieldData['custom_question_option_' + question.id ] ) } );
        } );
        form.append('custom_questions', JSON.stringify( customQuestionsAnswers ) );
      }
      
      form.append("descriptionOfWorks", fieldData.descriptionOfWorks);
      form.append("locationOfWorks", fieldData.locationOfWorks);
      form.append("stateGoodOrder", fieldData.stateGoodOrder);
      form.append("contractorId", fieldData.contractorId);
      form.append("date", fieldData.date);
      form.append("verifySignature", verifySignature);
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/ga2-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/ga2-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/view-own/ga2-permits')
        setFieldData(fieldData);
        handleClearVerifySign();
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        {/* <p className="redText mb-1">This Permit is valid for one shift only. </p>
        <p className="redText">You must ensure areas below you works are closed to prevent others access below your works. </p> */}
        <div className="addga2_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Date of Inspection<span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.date} disabled={!!id} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="date" />
                </div>
              </div>
              {error.date && <span className="err">{error.date}</span>}
            </div>
            
            <div className="col-3  p-2 location_ga2">
              <label for="">
                <div className="require-label">
                  {" "}
                  Inspected By <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="inspectedBy"
                    value={fieldData.inspectedBy}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.inspectedBy && (
                <span className="err">{error.inspectedBy}</span>
              )}
            </div>
            
            {companyId != 161 && <>
              <div className="col-3  p-2 location_ga2">

                <label for="">
                  <div className="require-label">
                    Serial Number <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Serial Number"
                      value={fieldData.serialNumber ? serialNumberData.filter(v => v.serialNumber == fieldData.serialNumber).map(d => ({
                        label: d.serialNumber,
                        value: d.serialNumber,
                        name: "serialNumber",
                      })) : null}
                      options={ fieldData.siteId == null ? [] : serialNumberData.filter(v => v.siteId == fieldData.siteId).filter(v => v.departmentId == fieldData.departmentId).map((d) => ({
                        label: d.serialNumber,
                        value: d.serialNumber,
                        name: "serialNumber",
                      })) }
                    />
                  </div>
                </div>
                {error.serialNumber && (
                  <span className="err">{error.serialNumber}</span>
                )}
              </div>
            </>}

            <div className="col-3  p-2 location_ga2"></div>
            {/* <p className="redText ms-2">Fire Watch : The person required to maintain a careful watch for during Hot works / Break Times and a minimum of 1 hour following the completion of the hot works.</p> */}
            {/* <p className="redText ms-2 mt-3 mb-1">Responsible Person : This Person is charged with the safe execution of all aspects of this permit.</p> */}
          </div>
        </div>
        <div className="addga2_second_container">
          <div className="general_details">Work Details</div>
          <div>
            <div className="col-8 form_inner p-2">
              <label for="">
              Description of Plant or Equipment and means of identification <span className="text-danger">*</span>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="descriptionOfWorks"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.descriptionOfWorks}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.descriptionOfWorks && (
                <span className="err">{error.descriptionOfWorks}</span>
              )}
            </div>
          </div>
        </div>
        <div className="addga2_fourth_container">
          <div className="general_details">Components / Items</div>
            
            <p className="additionalnotes">
              <span className="additionalnote">Yes = Inspected & good order</span>
              <span className="additionalnote">No = Inspected & action required</span>
              <span className="additionalnote">N/A = Not applicable to this inspection</span>
            </p>

          { ! id && customQuestions && customQuestions.length > 0 ? <>
            <div className="inspected_radio_btn">
              {customQuestions.map((item, index) => (
                <div>
                  <FormControl>
                    <FormLabel class="inspected_label">
                    {item.question}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name={'custom_question_option_' + item.id}
                      onChange={handleChange}
                    >
                      <FormControlLabel checked={typeof fieldData[ 'custom_question_option_' + item.id ] !== 'undefined' && fieldData[ 'custom_question_option_' + item.id ] == 1} value={1} control={<Radio />} label="Yes" />
                      <FormControlLabel checked={typeof fieldData[ 'custom_question_option_' + item.id ] !== 'undefined' && fieldData[ 'custom_question_option_' + item.id ] == 0} value={0} control={<Radio />} label="No" />
                      <FormControlLabel checked={typeof fieldData[ 'custom_question_option_' + item.id ] !== 'undefined' && fieldData[ 'custom_question_option_' + item.id ] == 2} value={2} control={<Radio />} label="N/A" />
                    </RadioGroup>
                  </FormControl>
                </div>
              ))}
            </div>
          </> : <>
            <div className="inspected_radio_btn">
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Rated capacity indicator / limiter
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="ratedCapacity"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.ratedCapacity == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.ratedCapacity == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.ratedCapacity == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Wire rope and chain systems
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="wireRopeChain"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.wireRopeChain == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.wireRopeChain == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.wireRopeChain == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Limit switches (e.g. Hoist, Derrik limit.)
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="limitSwitch"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.limitSwitch == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.limitSwitch == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.limitSwitch == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Ropes positioned on their sheaves
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="ropesPosition"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.ropesPosition == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.ropesPosition == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.ropesPosition == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Structure (major damage)
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="structure"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.structure == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.structure == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.structure == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Hooks or other load lifting attachments
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="hooksAndLoad"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.hooksAndLoad == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.hooksAndLoad == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.hooksAndLoad == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Hydraulic Systems
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="hydraulic"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.hydraulic == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.hydraulic == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.hydraulic == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Electrical systems
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="electical"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.electical == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.electical == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.electical == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Fuel Lines
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="fuelLines"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.fuelLines == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.fuelLines == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.fuelLines == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Brakes and clutches
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="brakesClutches"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.brakesClutches == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.brakesClutches == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.brakesClutches == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Operators cab
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="operatorsCab"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.operatorsCab == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.operatorsCab == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.operatorsCab == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Operators controls
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="operatorsControls"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.operatorsControls == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.operatorsControls == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.operatorsControls == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Anenometer, where provided
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="anemometer"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.anemometer == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.anemometer == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.anemometer == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                  Other matters (Manufacturer / user)
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="otherMatters"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.otherMatters == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.otherMatters == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.otherMatters == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Flashing Beacon working
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="flashingBeakon"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.flashingBeakon == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.flashingBeakon == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.flashingBeakon == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Reversing/Travel alarm working
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="reversingTravel"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.reversingTravel == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.reversingTravel == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.reversingTravel == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Safety Pins/QuickHitch all assosiated clips present and in good order
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="safetyPins"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.safetyPins == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.safetyPins == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.safetyPins == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Seat Belts
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="seatBelts"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.seatBelts == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.seatBelts == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.seatBelts == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </> }

          <div className="d-flex">
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  State whether in good order (See note below)
                  <span className="text-danger"> *</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="stateGoodOrder"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.stateGoodOrder}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              <div className="hold_down">
                Note: Result of inspection should state if all working gear and anchoring or fixing plant or gear is in good working order. Including where required the automatic safe load indicator and the derriking interlock.
              </div>
              {error.stateGoodOrder && <span className="err">{error.stateGoodOrder}</span>}
            </div>
          </div>
        </div>
        <div className="addga2_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
            {error.files && (
              <span className="err">{error.files}</span>
            )}
          </div>
        </div>
        <div className="addga2_sixth_container">
          <div className="general_details">Signature(s) <span className="text-danger"> *</span></div>
          <div className="p-2">
            <p className="hold_down">
              I have read and understood my employers Risk Assessment and Method Statement and the requirements of this permit.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>}
            {/* <p className="redText">
              <strong>Please Note:</strong> Work cannot be started until the permit is approved.
            </p> */}
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddGA2;
