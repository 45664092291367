import React, { useEffect } from "react";
import { useAppState } from "../../state";
import { Link, useHistory } from "react-router-dom";

import Cookies from "universal-cookie";
import axios from "axios";
import { toast } from 'react-toastify';


export const Empty = () => {
  const history = useHistory()
  const { isLoggedIn, user, refreshUserDetails } = useAppState();
  const [active, setActive] = React.useState(false);
  const currentPage = window.location.pathname.split("/")[1];

  useEffect(() => {
    const cookies = new Cookies();
    const userToken = localStorage.getItem("cs_token");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  }, [])
  return (
    <div className={`home-styles homepage`}
    style={{
        backgroundColor: '#e2e6ea',
    }}
    >
    </div>
  );
};
